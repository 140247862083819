body {
  * {
    box-sizing: border-box;
  }
}

%clearfix {
  *zoom: 1;
  &:after {
    content: '';
    display: table;
    line-height: 0;
    clear: both;
  }
}

a {
	text-decoration: none;
	&:hover, &:focus, &:active{
		outline: none;
	}
  &:visited {
    color: inherit;
  }
}

ul {
	list-style-type: none;
}

.container {
  padding-right: 10px;
  padding-left: 10px;
  margin-right: auto;
  margin-left: auto;
  position: relative;
}

.container {
  @extend %clearfix;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  padding: 0 10px;
}
@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .container {
    width: 990px;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1190px;
  }
}
