$or: 'OpenSansRegular';
$oeb: 'OpenSansExtraBold';
$ob: 'OpenSansBold';
$opl: 'OpenSansLight';
$opi: 'OpenSansItalic';
$osb: 'OpenSansSemibold';
$hl: 'HelveticaNeueCyrLight';
$hbl: 'HelveticaNeueCyrBlack';

@font-face {
font-family: 'OpenSansRegular';
src: url('../fonts/OpenSansRegular/OpenSansRegular.eot') format('embedded-opentype'),
url('../fonts/OpenSansRegular/OpenSansRegular.woff2') format('woff2'),
url('../fonts/OpenSansRegular/OpenSansRegular.woff') format('woff'),
url('../fonts/OpenSansRegular/OpenSansRegular.ttf') format('truetype'),
url('../fonts/OpenSansRegular/OpenSansRegular.svg') format('svg');
}

@font-face {
font-family: 'OpenSansExtraBold';
src: url('../fonts/OpenSansExtraBold/OpenSansExtraBold.eot') format('embedded-opentype'),
url('../fonts/OpenSansExtraBold/OpenSansExtraBold.woff2') format('woff2'),
url('../fonts/OpenSansExtraBold/OpenSansExtraBold.woff') format('woff'),
url('../fonts/OpenSansExtraBold/OpenSansExtraBold.ttf') format('truetype'),
url('../fonts/OpenSansExtraBold/OpenSansExtraBold.svg') format('svg');
}

@font-face {
font-family: 'OpenSansBold';
src: url('../fonts/OpenSansBold/OpenSansBold.eot') format('embedded-opentype'),
url('../fonts/OpenSansBold/OpenSansBold.woff2') format('woff2'),
url('../fonts/OpenSansBold/OpenSansBold.woff') format('woff'),
url('../fonts/OpenSansBold/OpenSansBold.ttf') format('truetype'),
url('../fonts/OpenSansBold/OpenSansBold.svg') format('svg');
}

@font-face {
font-family: 'OpenSansItalic';
src: url('../fonts/OpenSansItalic/OpenSansItalic.eot') format('embedded-opentype'), 
url('../fonts/OpenSansItalic/OpenSansItalic.woff2') format('woff2'), 
url('../fonts/OpenSansItalic/OpenSansItalic.woff') format('woff'), 
url('../fonts/OpenSansItalic/OpenSansItalic.ttf') format('truetype'), 
url('../fonts/OpenSansItalic/OpenSansItalic.svg') format('svg'); 
}

@font-face {
font-family: 'OpenSansSemibold';
src: url('../fonts/OpenSansSemibold/OpenSansSemibold.eot') format('embedded-opentype'),
url('../fonts/OpenSansSemibold/OpenSansSemibold.woff2') format('woff2'),
url('../fonts/OpenSansSemibold/OpenSansSemibold.woff') format('woff'),
url('../fonts/OpenSansSemibold/OpenSansSemibold.ttf') format('truetype'),
url('../fonts/OpenSansSemibold/OpenSansSemibold.svg') format('svg');
}
@font-face {
font-family: 'OpenSansLight';
src: url('../fonts/OpenSansLight/OpenSansLight.eot') format('embedded-opentype'),
url('../fonts/OpenSansLight/OpenSansLight.woff2') format('woff2'),
url('../fonts/OpenSansLight/OpenSansLight.woff') format('woff'),
url('../fonts/OpenSansLight/OpenSansLight.ttf') format('truetype'),
url('../fonts/OpenSansLight/OpenSansLight.svg') format('svg');
}
@font-face {
font-family: 'HelveticaNeueCyrLight';
src: url('../fonts/HelveticaNeueCyrLight/HelveticaNeueCyrLight.eot') format('embedded-opentype'),
url('../fonts/HelveticaNeueCyrLight/HelveticaNeueCyrLight.woff2') format('woff2'),
url('../fonts/HelveticaNeueCyrLight/HelveticaNeueCyrLight.woff') format('woff'),
url('../fonts/HelveticaNeueCyrLight/HelveticaNeueCyrLight.ttf') format('truetype'),
url('../fonts/HelveticaNeueCyrLight/HelveticaNeueCyrLight.svg') format('svg');
}
@font-face {
font-family: 'HelveticaNeueCyrBlack';
src: url('../fonts/HelveticaNeueCyrBlack/HelveticaNeueCyrBlack.eot') format('embedded-opentype'),
url('../fonts/HelveticaNeueCyrBlack/HelveticaNeueCyrBlack.woff2') format('woff2'),
url('../fonts/HelveticaNeueCyrBlack/HelveticaNeueCyrBlack.woff') format('woff'),
url('../fonts/HelveticaNeueCyrBlack/HelveticaNeueCyrBlack.ttf') format('truetype'),
url('../fonts/HelveticaNeueCyrBlack/HelveticaNeueCyrBlack.svg') format('svg');
}
