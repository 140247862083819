.kabinet-favorite, .kabinet-favorite-organizatsii{
  width: 100%;
  float: left;
  position: relative;
}

.kabinet-favorite-left{
  width: 20%;
  float: left;
  position: relative;
  padding-top: 70px;
  padding-bottom: 50px;
  box-sizing: border-box;
  @include breakpoint(max-width 992px) {
    display: none;
  }
  ul{
    width: 100%;
    float: left;
    display: inline-block;
    padding: 0;
    margin: 0;
    li{
      width: 100%;
      display: inline-block;
      list-style-type: none;
      margin: 5px 0;
      a{
        display: block;
        position: relative;
        float: left;
        width: 100%;
        text-decoration: none;
        font-size: 12px;
        font-family: $ob;
        color: $green;
        &:hover{
          text-decoration: underline;
        }
        .count{
          padding-left: 10px;
          box-sizing: border-box;
        }
      }
    }
  }
}
.kabinet-favorite-right{
  width: 80%;
  float: left;
  position: relative;
  padding-top: 20px;
  padding-bottom: 50px;
  box-sizing: border-box;
  border-left: 1px solid #dedede;
  @include breakpoint(max-width 992px) {
    width: 100%;
    border-left: none;
  }
  .breadcrumbs{
    padding-left: 50px;
    box-sizing: border-box;
    @include breakpoint(max-width 992px) {
      padding-left: 0;
    }
  }
  h2{
    padding-left: 50px;
    box-sizing: border-box;
    font-family: $or;
    font-size: 24px;
    color: #000;
    margin: 0;
    display: inline-block;
  }
  .top-menu{
    width: 100%;
    float: left;
    display: inline-block;
    text-align: right;

    .delete-knopka{
      display: inline-block;
      float: none;
      margin-right: 2%;
      font-size: 12px;
      font-family: $ob;
      color: #334a51;
      background-color: $bezh;
      border: 1px solid #dedede;
      border-radius: 12px;
      text-decoration: none;
      padding: 5px 10px;
      box-sizing: border-box;
      top: 0;
      position: relative;
      @include breakpoint(max-width 770px) {
        display: none;
      }
    }
    .checkbox0{
      float: right;
      @include breakpoint(max-width 770px) {
        display: none;
      }
    }
  }

  .kabinet-favorite-right-nav{
    padding: 25px 0 25px 50px;
    box-sizing: border-box;
    float: left;
    width: 100%;
    text-align: right;
    ul{
      padding-top: 10px;
      box-sizing: border-box;
      width: 48.6%;
      float: left;
      display: inline-block;
      position: relative;
      margin: 0;
      padding-left: 0;
      li{
        width: 45%;
        display: inline-block;
        list-style-type: none;
        position: relative;
        float: left;
        text-align: left;
        a{
          text-decoration: none;
          color: #6c5d0b;
          font-size: 14px;
          font-family: $ob;
        }
      }
      li.active{
        a{
          color: #000;
        }
      }
    }
    .delete-knopka{
      display: inline-block;
      float: left;
      margin-right: 2%;
      font-size: 12px;
      font-family: $ob;
      color: #334a51;
      background-color: $bezh;
      border: 1px solid #dedede;
      border-radius: 12px;
      text-decoration: none;
      padding: 5px 10px;
      box-sizing: border-box;
      top: 0;
      position: relative;
      @include green-hov-rgba;
    }
    .checkbox0{
      float: left;
    }
    .send-email{
      float: right;
      display: inline-block;
      font-size: 14px;
      font-family: $osb;
      color: #767676;
    }
  }

}

.kabinet-favorite-right-error{
  width: 100%;
  float: left;
  position: relative;
  text-align: center;
  padding-top: 50px;
  padding-bottom: 100px;
  box-sizing: border-box;
  p{
    width: 100%;
    display: inline-block;
    text-align: center;
    font-size: 14px;
    font-family: $ob;
    color: #000;
  }
  a{
    color: #000;
    text-decoration: underline;
    font-family: $osb;
    font-size: 14px;
    text-align: center;
    display: inline-block;

  }
}
.kabinet-favorite-right-item{
  width: 100%;
  float: left;
  position: relative;
  padding-left: 50px;
  padding-top: 10px;
  padding-bottom: 10px;
  box-sizing: border-box;
  border-bottom: 1px solid #dedede;
  @include breakpoint(max-width 992px) {
    padding-left: 0;
    .average-ad-item-content {
      width: 75%;
    }
  }
  @include breakpoint(max-width 770px) {
    width: 50%;
    padding: 10px;
    .average-ad-item-content {
      width: 100%;
    }
  }
  @include breakpoint(max-width 550px) {
    width: 100%;
  }
  &:last-of-type{
    border-bottom: none;
    @include breakpoint(max-width 770px) {
      border-bottom: 1px solid #dedede;
    }
  }
  .checkbox0 {
    @include breakpoint(max-width 770px) {
      display: none;
    }
  }
  .average-ad-item{
    width: calc(100% - 20px);
    float: left;
    display: inline-block;
    border-bottom: none;
    @include breakpoint(max-width 770px) {
      width: 100%;
    }
    .average-ad-item-thumb{
      img{
        overflow: hidden;
        object-fit: cover;
      }
    }
    .average-ad-item-content{
      .average-ad-star{
        z-index: 3;
      }
      .average-ad-title{
        font-size: 16px;
      }
    }
  }
}

.average-ad-item-hide{
  opacity: 0.3;
}

.close-ad{
  font-family: $or;
  font-size: 12px;
  text-align: center;
  padding: 5px;
  background-color: #fff3a0;
  display: inline-block;
  position: absolute;
  min-width: 210px;
  left: 22%;
bottom: 26%;
  z-index: 1;
  @include breakpoint(max-width 600px) {
    right: 0;
    left: auto;
  }
}
.close-ad__fon{
  display: inline-block;
  width: 80%;
  position: absolute;
  top: 0;
  left: 5%;
  background-color: rgba(51, 74, 81, 0);
  height: 100%;
  @include breakpoint(max-width 770px) {
    width: 100%;
    left: 0;
  }
}

.kabinet-favorite-right__search-item{
  width: 100%;
  float: left;
  padding: 20px 0 20px 50px;
  box-sizing: border-box;
  margin: 10px 0;
  border-bottom: 1px solid #dedede;
  strong{
    float: left;
    font-family: $ob;
    font-size: 18px;
    color: #334a51;
  }
  .kabinet-favorite-right__search-item_ad{
    width: 70%;
    float: left;
    display: inline-block;
  }
  .average-ad-star{
    float: left;
    margin-top: 7px;
    margin-left: 30px;
  }
  .vip-ad-geo{
    width: 50%;
  }
  .checkbox0{
    float: left;
  }
}


.switch{
  position: relative;
  display: inline-block;
  width: 6%;
  float: left;
  left: 19%;
  margin-top: 5px;

  .cmn-toggle {
    position: absolute;
    margin-left: -9999px;
    visibility: hidden;
  }
  .cmn-toggle + label {
    display: block;
    position: relative;
    cursor: pointer;
    outline: none;
    user-select: none;
  }
  input.cmn-toggle-round + label {
  padding: 2px;
  width: 100px;
  height: 15px;
  background-color: #dddddd;
  border-radius: 60px;
}
  input.cmn-toggle-round + label:before,
  input.cmn-toggle-round + label:after {
    display: block;
    position: absolute;
    top: 1px;
    left: 1px;
    bottom: 1px;
    content: "";
  }
  input.cmn-toggle-round + label:before {
    right: 1px;
    background-color: #f1f1f1;
    border-radius: 60px;
    transition: background 0.4s;
  }
  input.cmn-toggle-round + label:after {
    width: 20px;
    height: 20px;
    top: -3px;
    border: 1px solid #bbbaba;
    background-color: #fff;
    border-radius: 100%;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    transition: margin 0.4s;
  }
  input.cmn-toggle-round:checked + label:before {
    background-color: $green;
  }
  input.cmn-toggle-round:checked + label:after {
    margin-left: 32px;
  }
}

.kabinet-favorite-right__search-item:last-of-type{
  border-bottom: none;
}
.kabinet-favorite-right_top-menu{
  float: left;
  padding: 0 0 0 50px;
  box-sizing: border-box;
  margin: 0;
  display: inline-block;
  text-align: left;
  @include breakpoint(max-width 992px) {
    padding-left: 0;
  }
  li{
    display: inline-block;
    list-style-type: none;
    min-width: 10%;
    margin: 0 10px;
    a{
      font-family: $ob;
      font-size: 12px;
      color: #6f610e;
      text-decoration: none;
      border-bottom: 1px dashed #c4e4f2;

    }
    &:first-of-type{
      margin-left: 0;
    }
  }
  li.active{
    a{
      color: #000;
      border-bottom: none;
    }
  }
}

.kabinet-favorite-organizatsii{
  .kabinet-favorite-right-item{
    .average-ad-item{

      .average-ad-item-content{
        .top-content{
          p{
            width: 100%;
            float: left;
          }
          .average-ad-title{
            color: $link;
          }
        }
        .bottom-content{
          .left, .right{
            width: 35%;
            float: left;
            padding-top: 0;
            text-align: left;
            border: none;
          }
          .right {
            @include breakpoint(max-width 770px) {
              width: 100%;
              display: flex;
              flex-direction: column;
            }
          }
          .shops-tel{
            float: left;
            font-family: $ob;
            color: $green;
            font-size: 14px;
            text-align: left;
          }
          .shops-link{
            display: none;
            text-transform: uppercase;
            color: #fff;
            // display: inline-block;
            padding: 7px 25px;
            box-sizing: border-box;
            background-color: $green;
            border-radius: 15px;
            float: left;
            font-size: 9px;
            margin-top: 10px;
            @include green-hov-rgba;
          }
        }

      }

    }
  }
}
