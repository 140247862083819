.header__top{
	width: 100%;
	float: left;
	display: block;
	padding: 17px 0;
	>.container {
		display: flex;
	}
}
.line{
	&:hover{
		border-bottom: 1px solid #1a97bb;
	}
}
.header__top_left{
	display: flex;
	align-items: center;
	flex:1 ;
	.mob-append-btn,
	.mob-menu-btn {
		display: none;
		height: 30px;
		width: 30px;
		margin: 0px 5px;
		border: none;

	}
	.mob-append-btn {
		background-color: #334a51;
		border-radius: 50%;
		color: #fff;
		font-size: 27px;
		line-height: 26px;
		display: none;
		align-items: center;
		justify-content: center;
		transition: .4s;
		//outline: none;
		@include breakpoint(max-width 680px) {
			display: flex;
		}
		&-active {
			transform: rotate(405deg);
		}
	}
	.mob-append-menu {
		display: none;
		position: absolute;
		top: 100%;
		left: 0;
		width: 100%;
		text-align: center;
		background-color: #fff;
		z-index: 100;
		h3 {
			display: block;
			margin: 10px 0;
		}
		a {
			display: inline-block;
			text-align: center;
			min-width: 45px;
			width: auto;
			margin: 10px 30px;
			background-color: #334a51;
			border-radius: 20px;
			font-family: $ob;
			font-size: 11px;
			text-transform: uppercase;
			color: #fff;
			text-decoration: none;
			padding: 5px 7px;
			line-height: 20px;
			box-sizing: border-box;
			clear: both;
		}
	}
	.mob-menu-btn {
		background-color: transparent;
		padding: 0;
		@include breakpoint(max-width 680px) {
			display: block;
		}
		span {
			display: block;
			margin: 0 auto;
			position: relative;
			width: 25px;
			height: 3px;
			background-color: #334a51;
			transition: .4s;
			&:before {
				content: '';
				display: block;
				position: absolute;
				top: -8px;
				width: 25px;
				height: 3px;
				background-color: #334a51;
				transition: .4s;
			}
			&:after {
				content: '';
				display: block;
				position: absolute;
				top: 8px;
				width: 25px;
				height: 3px;
				background-color: #334a51;
				transition: .4s;
			}
		}
		&-active {
			span {
				background-color: transparent;

				&:before {
					transform: rotate(45deg);
					transition: .4s;
					top: 0;
				}
				&:after {
					transform: rotate(-45deg);
					transition: .4s;
					top: 0;
				}
			}
		}
	}
	.mob-menu-list {
		display: none;
		position: absolute;
		top: 100%;
		right: 0;
		width: 200px;
		padding: 0 25px;
		background-color: #2f474e;
		z-index: 100;
		@include breakpoint(max-width 680px) {
			left: 0;
			width: 165px;
		}
	}
	.mob-menu-section {
		display: block;
		border-bottom: 2px solid #fff;
		padding: 5px 0;
		&:last-of-type {
			border-bottom: none;
		}

	}
	.mob-menu-item {
		display: block;
		color: #fff;
		font-size: 12px;
		padding: 3px 0;
		@include breakpoint(max-width 680px) {
			font-size: 9px;
			padding: 5px 0;
		}
	}
}
.header__top_user-vhod{
	text-align: right;
	display: flex;
	align-items: center;
  margin-top: 2px;
	margin-left: auto;
	@include breakpoint(max-width 770px){
		margin-top: 0;

	}
	@include breakpoint(max-width 670px){
		margin-top: 0;
	}
}
.header__top_left h1{
	float: left;
	position: relative;
	display: inline-block;
	margin: 0;
	font-size: initial;
	width: 100px;
	margin-right: 20px;
	@include breakpoint(max-width 670px){
		margin-right: 12px;
		width: 90px;
	}
	.header__top_logo{
		width: 100%;
		background-image: url("../img/Logotip_RUBON.png");
		background-size: contain;
		display: block;
		background-repeat: no-repeat;
		background-position-y: center;
    height: 31px;
    text-indent: -1000px;
	}
}
.header__top_logo{
	max-width: 120px;
	width: 20%;
	float: left;
	overflow: hidden;
	margin-right: 20px;
	@include breakpoint(max-width 680px) {
		width: 70px;
	}
	img{
		object-fit: contain;
		width: 100%;
	}
}
.append-button{
	//width: 75%;
	float: left;
	position: relative;
	margin-top: 3px;
	display: flex;
	@include breakpoint(max-width 680px) {
		display: none;
	}
	a {
		text-align: center;
		min-width: 45px;
		float: left;
		margin-right: 20px;
		background-color: #334a51;
		border-radius: 20px;
		font-family: $ob;
		font-size: 11px;
		text-transform: uppercase;
		color: #fff;
		text-decoration: none;
		padding: 2px 10px;
		line-height: 20px;
		box-sizing: border-box;
		display: flex;
		align-items: center;
		@include green-hov-rgba;

		// height: 35px;
		// &:first-of-type{
		// 	color: #ffd800;
		// 	.plus-icon{
		// 		font-size: 17px;
		// 		color: #ffd800;
		// 	}
		// }
		.plus-icon{
			font-size: 17px;
			color: #fff;
			display: inline-block;
			position: relative;
			margin-right: 5px;
		}
		&:last-of-type{
			margin-right: 0;
		}
	}
	@include breakpoint(max-width 992px){
		margin-top: 0;
		a{
			font-size: 9px;
			&:first-of-type{
				margin-right: 12px;
			}
		}
	}
	@include breakpoint(max-width 680px){
		a {
			font-size: 9px;
			width: 49.4%;
			&:first-of-type{
				margin-right: 4px;
			}
		}
	}
	@include breakpoint(max-width 680px){
		width: 100%;
		margin-top: 0;
		a { font-size: 10px;
			span{
				top: 2px;
			}
		}
	}
}
.header__login, .header__regist{
  min-width: 25%;
  display: inline-block;
  float: none;
	padding: 2px 15px;
	box-sizing: border-box;
  font-size: 13px;
  font-family: $ob;
	font-style: italic;
  color: #282828;
  line-height: 20px;
  cursor: pointer;
  text-decoration: none;
	text-align: center;

  &:hover{
    color: #1a97bb;
  }
}
.header__regist {
	@include breakpoint(max-width 680px) {
		display: none;
	}
}
.header__login{
	border: 0.5px solid #000002;
	border-radius: 19px;
	font-style: normal;
	.login-icon{
		width: 22px;
		height: 16px;
		background-image: url("../img/login.png");
		display: inline-block;
		position: relative;
    top: 3px;
	}
}
.header_top_icon{
	height: 19px;
	width: 17px;
	background-repeat: no-repeat;
	float: left;
	margin-right: 10px;
	position: relative;

}
.regist-user{
	background-image: url('../img/regist-user.png');
  background-position: 0px 0px;
	width: 18px;
	height: 18px;
	cursor: pointer;
}
.login-header{
	background-image: url('../img/pic-login.png');
background-position: 0px 0px;
	width: 18px;
	height: 18px;
	cursor: pointer;
}


