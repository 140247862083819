.breadcrumbs {
  float: left;
  width: 100%;
  position: relative;
  padding: 0;
  &__list {
    list-style-type: none;
    float: left;
    margin: 0;
    // margin: 15px 0 15px;
    padding: 0;
    li {
      display: inline-block;
      //margin: 0 10px;
      position: relative;
      font-size: 10px;
      font-family: $or;
      a {
        color: #334a51;
      }
      &:first-of-type {
        margin-left: 0;
      }
      &:after {
        content: "/";
        position: relative;
        right: 0;
        bottom: 0;
        margin-left: 5px;
      }
      &:last-of-type {
        line-height: 30px;
        a {
          float: right;
          line-height: 30px;
          font-size: 12px;
          color: #334a51;
          font-family: $or;
          padding-left: 20px;
          box-sizing: border-box;
        }
        &:after, &:before {
          content: none;
        }
      }
    }
  }
}

#modalAds {
  .modal.in .modal-dialog {

    .modal-content {
      .modal-header {
        padding: 20px;
      }
      .modal-body {
        .modal-wrap {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          flex-direction: row;
          align-items: center;
          width: 100%;
          position: relative;
          p {
            width: 100%;
          }
          .publish {
            color: #fff !important;
            margin: 0 10px;
          }
        }
      }
    }

  }
}

//.display-block {
//  display: block!important;
//}
.fancybox-overlay{
  z-index: 1!important;
}
