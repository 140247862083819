.header__middle-ad {
  width: 100%;
  float: left;
  display: block;
  background-color: #fff;
  height: 50px;
  border-bottom: 1px solid #dedede;
}

.dobavit-home {
  display: block;
  width: 35%;
  float: left;
  margin-right: 15px;
  a {
    float: left;
    text-decoration: none;
    color: #000;
    width: 90%;
    line-height: 18px;
    font-size: 16px;
    font-family: $ob;
    padding-left: 10px;
    position: relative;
  }
}

.header__bottom-ad {
  width: 100%;
  float: left;
  display: block;
}

.ad-search {
  width: 80%;
  float: left;
  display: block;
  position: relative;
}

.search-pic {
  background-image: url('../img/adsearchpic.png');
  position: absolute;
  background-repeat: no-repeat;
  display: block;
  float: left;
  width: 20px;
  height: 23px;
  z-index: 2;
  left: 25px;
  top: 7px;

}

.input-search-ad {
  float: left;
  display: inline-block;
  height: 37px;
  width: 90%;
  line-height: 37px;
  background-color: #fff;
  border: 1px solid #dedede;
  border-right: 1px solid transparent;
  padding-left: 50px;
  z-index: 1;
  position: relative;
  outline: none;
  font-family: $opi;
  font-size: 16px;
}

.adsearch-button {
  float: left;
  display: inline-block;
  width: 10%;
  background-color: $green;
  height: 37px;
  line-height: 37px;
  text-align: center;
  text-transform: uppercase;
  font-size: 13px;
  font-family: $osb;
  color: #fff !important;
  text-decoration: none;
  border: 1px solid #dedede;
  @include green-hov-rgba;
  // &:hover{
  //   background-color: #fff;
  //   text-decoration: none;
  // }
}

.ad-baner {
  width: 100%;
  float: left;
  height: 48px;
  display: block;
  background-color: #f1f1f1;
}

.adcontent {
  float: left;
  display: block;
  width: 100%;
}

.delivery_block {
  width: 20%;
  padding: 0;
  background-color: #334a51;
  font-size: 11px;
  display: block;
  float: left;
  position: relative;
}

.delivery_block .delivery_list {
  padding: 0px 6px 3px 6px;
  height: 35px;
  cursor: pointer;
  margin: 0 20px 0 0;
  color: #ed4226;
  background-color: #334a51;
  width: 100%;
  z-index: 11;

}

.delivery_list #btn {
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 12px solid #ffd800;
  float: right;
  position: absolute;
  right: 5%;
  top: 10px;

}

.delivery_list span {
  padding: 2px 0 0 20px;
  display: block;
  font-weight: bold;
  font-size: 16px;
  font-family: $osb;
  color: #fff;
  height: 35px;
  line-height: 30px;
}

.delivery_block ul.cities_list {
  margin-top: 0px;
  z-index: 10;
  background-color: #fff6c4;
  position: absolute;
  width: 100%;
  padding: 0;

  display: none;
}

ul.cities_list li {
  padding: 6px 0px 6px 30px;
  list-style-type: none;
  font-family: $or;
  font-size: 16px;
  color: #334a51;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    background-image: url("../img/disk.png");
    width: 5px;
    height: 5px;
    margin-left: -20px;
    margin-top: 10px;

  }

}

ul.cities_list li:hover {
  cursor: pointer;
  background: #fff;
}

.delivery_block .delivery_text {
  padding: 3px 5px;
  margin: 5px 0 0 3px;
}

.yellow-block {
  width: 80%;
  float: left;
  display: block;
  background-color: #ffd800;
  height: 35px;
}

.adcontent-main {
  width: 100%;
  float: left;
  display: block;
}

.ad-charasteristics {
  width: 20%;
  float: left;
  display: block;
  position: relative;
  z-index: 2;
  @include breakpoint(max-width 992px) {
    width: 25%;
  }
  @include breakpoint(max-width 770px) {
    display: none;
    width: 250px;
    margin: 0 auto;
    float: none;
  }

}

.ad-charasteristics__mob-btn {
  display: none;
  @include breakpoint(max-width 670px) {
    width: 100%;
    display: inline-block;
    text-align: center;
    width: 100%;
    float: left;
    margin-right: 20px;
    background-color: #334a51;
    border-radius: 20px;
    font-family: OpenSansBold;
    font-size: 12px;
    text-transform: uppercase;
    color: #fff;
    text-decoration: none;
    padding: 8px 10px;
    line-height: 20px;
    box-sizing: border-box;
    margin: 10px 0;
  }
}

#sidebar-filter {
  margin: 0;
  top: 45px;
  position: -webkit-sticky;
  position: sticky;

}

.ad-charasteristics-form {
  width: 100%;
  float: left;
  display: block;
  position: relative;
  padding: 0 15px 5px 15px;
  transition: transform 1s ease-out;
  .apply-button {
    background-color: $green;
    color: #fff;
    font-family: $ob;
    font-size: 14px;
    margin: 10px auto;
    display: inline-block;
    float: none;
    border: none;
    border-radius: 20px;
    padding: 5px 10px;
    box-sizing: border-box;
    cursor: pointer;
    outline: none;
    left: 21%;
    position: relative;
    @include green-hov-rgba;
  }
  @include breakpoint(max-width 670px) {
    display: none;
  }
}

.double-select {
  width: 100%;
  // 	height: 25px;
  // 	border-radius: 12px;
  float: left;
  display: inline-block;
  // 	border: 1px solid #e6e6e6;
}

.small-select {
  width: 50%;
  float: left;
  height: 25px;
  padding-left: 10px;
  border-radius: 12px;
  color: #464646;
  font-family: $or;
  font-size: 14px;
  -webkit-appearance: none;
  background-image: url('../img/select-arrow.png');
  background-position: 95% 50%;
  background-repeat: no-repeat;
  border: 1px solid #e6e6e6;
  line-height: 24px;
  -moz-appearance: none;
  text-indent: 0.01px;
  text-overflow: '';
  -ms-appearance: none;
  appearance: none !important;
  outline: none;
  margin: 5px 0;
  cursor: pointer;
  &:hover {
    background-color: #334a51;
    color: #fff;
  }
}

.small-select-1 {
  position: relative;
  z-index: 1;
}

.small-select-2 {
  border-left: none;
  z-index: 0;
  position: relative;
  width: 58%;
  margin-left: -18px;
  padding-left: 25px;
}

.large-select {
  width: 100%;
  float: left;
  height: 25px;
  padding-left: 10px;
  border-radius: 12px;
  color: #464646;
  font-family: $or;
  font-size: 14px;
  -webkit-appearance: none;
  background-image: url('../img/select-arrow.png');
  background-position: 95% 50%;
  background-repeat: no-repeat;
  border: 1px solid #e6e6e6;
  line-height: 24px;
  -moz-appearance: none;
  text-indent: 0.01px;
  text-overflow: '';
  -ms-appearance: none;
  appearance: none !important;
  outline: none;
  margin: 5px 0;
  cursor: pointer;
  &:hover {
    background-color: #334a51;
    color: #fff;
  }
}

.large-label {
  font-family: $or;
  font-size: 9px;
  color: #7e7e7e;
  position: relative;
  margin-left: 20px;
  top: 4px;
  min-width: 45%;
  margin-bottom: -1px;
  display: inline-block;
  float: left;
  z-index: 2;
}

.small-label {
  font-family: $or;
  font-size: 9px;
  color: #7e7e7e;
  position: relative;
  top: 4px;
  margin-left: -7px;
  width: 31%;
  float: left;
  margin-bottom: -1px;
  display: inline-block;
  float: left;
  z-index: 2;
}

.large-label-title {
  background-color: #fff;
}

.ad-charasteristics-form-type, .ad-charasteristics-form-dop, .ad-charasteristics-form-priece {
  width: 100%;
  float: left;
  display: block;
  position: relative;
  padding: 10px 0;
  border-bottom: 1px solid #dedede;
  border-top: 1px solid #dedede;
}

.ad-charasteristics-form-dop, .ad-charasteristics-form-priece {
  border-top: none;
}

.ad-charasteristics-form-priece {
  border-bottom: none;
}

.ad-charasteristics-form-type-title {
  font-size: 16px;
  font-family: $osb;
  margin-top: 0;
}

.line-type {
  width: 100%;
  float: left;
  display: inline-block;
}

.input-checkbox {
  display: none;
}

.label-checkbox {
  float: left;
  display: inline-block;
  position: absolute;
  background-image: url('../img/checkbox-sprite.png');
  background-position: 0px 0px;
  background-repeat: no-repeat;
  size: 14px;
  cursor: pointer;
}

input[type=checkbox]:checked + label {
  background-image: url('../img/checkbox-sprite.png');
  background-repeat: no-repeat;
  height: 14px;
  display: block;
  background-position: -18px 0px;
  width: 14px;
  cursor: pointer;
}

input[type=radio]:checked + label {
  background-image: url('../img/checkbox-sprite.png');
  cursor: pointer;
  background-repeat: no-repeat;
  height: 14px;
  display: block;
  background-position: -18px 0px;
  width: 14px;
}

.text-type {
  float: left;
  display: inline-block;
  font-family: $or;
  font-size: 14px;
  padding-left: 20px;
  line-height: 14px;
}

.ad-content-main {
  float: right;
  width: 80%;
  display: block;
  height: auto;

  box-sizing: border-box;
  @include breakpoint(max-width 992px) {
    width: 75%;
  }
  @include breakpoint(max-width 770px) {
    width: 100%;
  }
}

.nachalo {
  width: 100%;
  float: left;
  display: inline-block;
  background-color: #fff6c4;
  padding: 5px;
  border-bottom: 1px solid #dedede;
  p {
    font-size: 16px;
    font-family: $opi;
    color: #334a51;
    float: left;
    display: inline-block;
    padding: 0;
    padding-left: 10px;
    padding-right: 10px;
    margin: 0;
    @include breakpoint(max-width 400px) {
      width: 100%;
      padding: 0;
    }
  }
}

.price-category {
  margin-right: 10px;
  float: left;
  display: inline-block;
  font-size: 16px;
  font-family: $or;
  color: #b6a545;
  text-align: center;
}

.search-map {
  width: 100%;
  float: left;
  display: block;
  background-image: url("../img/search-map-long.png");
  background-repeat: no-repeat;
  height: 50px;
  padding: 18px 0;
  background-size: 100%;
  @include breakpoint(max-width 992px){
    background-size: cover;
  }
  p {
    font-size: 16px;
    font-family: $osb;
    text-align: center;
    color: #334a51;
    position: relative;
    margin: 0;
  }
}

.geo-pic {
  background-image: url("../img/geo-pic.png");
  background-repeat: no-repeat;
  position: absolute;
  height: 34px;
  width: 21px;
  top: -7px;
  margin-left: -35px;
}

.rect-new {
  display: inline-block;
  position: relative;
  color: #fff;
  font-size: 12px;
  font-family: $or;
  line-height: 14px;
  min-width: 40px;
  height: 15px;
  background-color: #f78900;
  margin-left: 10px;
  top: -2px;
  &:before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-top: 3px solid transparent;
    border-right: 5px solid #f78900;
    border-bottom: 3px solid transparent;
    left: -5px;
    top: 25%;
  }
}

.average-ad {
  float: left;
  display: flex;
  flex-wrap: wrap;
  width: 73%;
  // padding-right: 10px;
  border-right: 1px solid #dedede;
  border-top: 1px solid #dedede;
  border-left: 1px solid #dedede;
  @include breakpoint(max-width 992px) {
    width: 100%;
  }
  .append-button {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #dedede;
    padding-bottom: 20px;
    box-sizing: border-box;
    a {
      margin: 0 auto;
      float: none;
    }
  }
  @include breakpoint(max-width 770px) {
    width: 100%;
    border: none;
  }
}

//.adpage{
//	position: relative;
//	z-index: 20;
//	margin-top: 20px;
//	.header__bottom-home-left{
//		padding: 0 15px;
//		box-sizing: border-box;
//		.select-category{
//			font-size: 13px;
//		}
//		@include breakpoint(max-width 670px){
//			padding: 0;
//		}
//	}
//	.header__bottom-home-right{
//		margin-left: 0;
//	}
//}
.fixed_addpage {
  position: fixed;
  left: 0;
  margin: 0;
  padding: 5px 0;
  box-sizing: border-box;
  background-color: #fff;
  transition: transform 1s ease-out;
}

.right-sidebar {
  float: right;
  display: block;
  width: 27%;
  padding-left: 10px;
  box-sizing: border-box;
  margin-bottom: 20px;
  .banner-rightbar {
    float: right;
  }
  @include breakpoint(max-width 992px) {
    display: none;
  }
}

.average-ad-item, .ad-author__content_left-item {
  width: 100%;
  float: left;
  display: flex;
  padding: 11px 0 11px 1px;
  border-bottom: 1px solid #dedede;
}

.average-ad-item {
  display: flex;
  @include breakpoint(max-width 770px) {
    flex-direction: column;
    width: 48%;
    margin-bottom: 20px;
  }
  @include breakpoint(max-width 660px) {
    width: 100%;
    margin: 0 !important;
  }
}

.selected-average-ad-item {
  background-color: #fff6c4;
}

.average-ad-item-thumb {
  width: 25%;
  float: left;
  display: block;
  height: 124px;
  position: relative;
  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  > span {
    display: block;
    height: 50px;
    width: 50px;
    position: absolute;
    top: -10px;
    right: -10px;
    @include breakpoint(max-width 500px) {
      width: 65px;
      height: 65px;
      top: 0;
      right: 0;
    }
    img {
      height: 100%;
      width: 100%;
      display: block;
      object-fit: cover;
    }
  }
  @include breakpoint(max-width 770px) {
    width: 100%;
    height: 150px;
  }
}

.average-ad-item-content {
  width: 75%;
  float: left;
  display: flex;
  flex-direction: column;
  position: relative;
  padding-left: 15px;
  @include breakpoint(max-width 992px) {
    width: 100%;
    padding: 0 5px;
  }
}

.average-ad-time {
  width: 100%;
  float: left;
  display: inline-block;
  color: #878787;
  font-family: $or;
  font-size: 12px;
  margin: 0;
}

.average-ad-category {
  font-size: 12px;
  font-family: $or;
  color: $link;
  margin: 0 4px;
  float: left;
  display: inline-block;
  &:first-of-type {
    margin-left: 0 !important;
  }
}

.bottom-content {
  width: 100%;
  position: relative;
  margin-top: auto;
}

.top-content {
  width: 100%;
  float: left;
  position: relative;
  display: flex;
  @include breakpoint(max-width 770px) {
    flex-direction: column-reverse;
  }
}

.top-content-left {
  width: calc(100% - 95px);
  padding-right: 15px;
  @include breakpoint(max-width 770px) {
    width: 100%;
  }
}

.top-content-right {
  width: 150px;
  display: flex;
  flex-direction: column;
  @include breakpoint(max-width 770px) {
    flex-direction: row;
    width: 100%;
    margin: 10px 0;
  }
  > span {
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    img {
      padding-right: 5px;
      height: 17px;
      width: 17px;
      object-fit: contain;
    }
    span {
      font-size: 9px;
      @include breakpoint(max-width 770px) {
        display: none;
      }
    }
  }
  .average-ad-price {
    margin-right: auto;
    width: 100%;
    text-align: right;
    float: none;
    justify-content: flex-end;
    @include breakpoint(max-width 770px) {
      justify-content: flex-start;
    }
  }
}

.average-ad-category:first-of-type {
  margin-left: 0 !important;
}

.average-ad-title, .vip-ad-title {
  float: left;
  width: 100%;
  font-size: 18px;
  font-family: $ob;
  color: #334a51;
  text-decoration: none;
  text-align: left;
  //margin-top: 15px;
  @include breakpoint(max-width 770px) {
    width: 100%;
  }
}

.vip-ad-title {
  color: #1a97bb;
  font-size: 16px;
  width: 100%;
}

.average-ad-geo, .vip-ad-geo {
  font-size: 12px;
  font-family: $or;
  color: #464646;
  padding-left: 15px;
  position: relative;
  float: left;
  width: 100%;
  margin: 10px 0;
}

.vip-ad-geo {
  margin: 5px 0;
}

.geo-space, .vip-geo-space {
  background-image: url('../img/geo-place.png');
  background-repeat: no-repeat;
  height: 12px;
  width: 10px;
  position: absolute;
  left: 0;
  top: 3px;
}

.vip-geo-space {
  top: 3px;
}

.average-ad-price, .vip-ad-price {
  float: left;

  text-align: left;
  font-family: $ob;
  font-size: 18px;
  color: #000;
  .rubl-icon, .rubl-icon svg {
    display: inline-block;
    width: 16px;
    height: 13px;
    position: relative;
    top: -1px;
    left: -1px;
  }
}

.vip-ad-price {
  padding-bottom: 10px;
  width: 100%;
}

.average-ad-price {
  min-width: 25px;
  padding-right: 10px;
  box-sizing: border-box;
  //margin-top: 15px;
  @include breakpoint(max-width 770px) {
    float: left;
  }
}

.average-ad-star, .vip-ad-star {
  height: 14px;
  width: 14px;
  display: block;
  z-index: 1;
  background-image: url('../img/star.png');
  background-repeat: no-repeat;
  margin-right: 10px;
  position: absolute;
  right: 0;
  @include breakpoint(max-width 992px){
    margin-right: 0;
  }
}

.active-star-icon {
  background-image: url('../img/star.png');
  background-position: -14px 0px;
  width: 14px;
  height: 14px;
  cursor: pointer;
}

.star-icon {
  background-image: url('../img/star.png');
  background-position: 0px 0px;
  width: 14px;
  height: 14px;
  cursor: pointer;
}

.vip-ad-star {
  position: relative;
  float: left;
  display: block;
  margin-right: 0;
  margin-left: 18px;
}

.title-vip-ad {
  font-family: $ob;
  font-size: 14px;
  color: #000;
  margin-top: 0;
  text-align: left;
  display: inline-block;
  float: left;
  width: 100%;
  padding: 0;
}

.vip-ad-item {
  float: left;
  width: 100%;
  display: block;
  border-bottom: 1px solid #dedede;
  margin-bottom: 25px;

}

.vip-ad-item-thumb {
  float: left;
  display: inline-block;
  width: 85%;
  height: 124px;
  position: relative;
  img {

    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
  }
}

.vip-ad-item-content {
  float: left;
  width: 100%;
  display: inline-block;
}

.whatisVIP {
  display: inline-block;
  width: 100%;
  float: left;
  text-align: left;
  font-family: $ob;
  font-size: 14px;
  color: #1a97bb;
}

.pagination {
  width: 73%;
  padding: 20px;
  position: relative;
  display: inline-block;
  ul {
    text-align: center;
    padding: 0;
    li {
      display: inline-block;
      margin: 0 1px;
      vertical-align: middle;
      line-height: 12px;
      padding: 2px 6px;
      a {
        font-size: 14px;
        color: #334a51;
        font-family: $or;
      }
    }
  }
  @include breakpoint(max-width 770px) {
    width: 100%;
  }
}

.pagination-prew, .pagination-next {
  background-image: url("../img/left-green-ar.png");
  width: 12px;
  height: 18px;
  position: relative;
  cursor: pointer;
  background-size: contain;
  background-repeat: no-repeat;
  top: 3px;
}

.pagination-next {
  background-image: url("../img/right-green-ar.png");
}

.pagination ul li a, .pagination ul li span {
  color: #666f80;
}

.pagination ul li a:hover, .pagination ul li span:hover {
  background: #f5f5f5;
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px;
}

.pagination ul li.active a, .pagination ul li.active span {
  color: #2d4c80;
  font-size: 16px;
  font-weight: bold;
}

.pagination ul li.active a:hover, .pagination ul li.active span:hover {
  background: none;
}

.link-category-ad {
  width: 75%;
  float: left;
  display: block;
  position: relative;
  padding: 0 20px 20px 20px;

}

.link-category-ad-item {
  width: 33.3%;
  float: left;
  display: block;
}

.link-category-ad-item-title {
  font-size: 12px;
  font-family: $ob;
  color: #000;
  margin-top: 15px;
  margin-bottom: 0;
}

.link-category-ad-item-column {
  padding-left: 0;
  li {
    a {
      font-size: 12px;
      font-family: $or;
      color: #d0c277;
    }
  }
}

.seo-text {
  width: 73%;
  float: left;
  display: inline-block;
  border-top: 1px solid #dedede;
  padding: 20px 15px;
  p {
    font-family: $or;
    font-size: 12px;
    text-align: left;
  }
  @include breakpoint(max-width 770px) {
    width: 100%;
  }
}

#price, #price2 {
  border: 1px solid #e1e1e1;
  padding: 5px;
  width: 65%;
  display: inline-block;
  color: #000;
}

#options {
  display: block;
  width: 100%;
  float: left;
  label {
    width: 45%;
    color: #6f6f6f;
  }
}

#slider_price {
  margin-top: 10px;
  background-color: #e3e3e3;
  position: relative;
  span {
    cursor: pointer;
  }
}

.ui-slider-range {
  background-color: $bezh;
}

.ui-slider .ui-slider-handle {
  background-color: $green;
  cursor: pointer;
  outline: none;
  width: 11px;
  height: 12px;
  position: absolute;
  top: 11px;
  &::before {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    margin-top: -10px;
    border-left: 6px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 10px solid $green;
  }
}

.ui-slider-horizontal {
  cursor: pointer;
}

.title-none_ad {
  width: 100%;
  float: left;
  text-align: center;
  font-family: $ob;
  color: $green;
  margin: 40px 0;
}

.search-warning {
  width: 100%;
  float: left;
  padding-left: 50px;
  box-sizing: border-box;
  padding-top: 20px;
  padding-bottom: 20px;
  .average-ad-item-content {
    float: left;
    width: calc(100% - 101px);
    .search-warning-title {
      font-family: $ob;
      text-align: left;
      color: $green;
      font-size: 24px;
      line-height: 80px;
    }
  }
  .append-button {
    border: none;
    margin-top: 20px;
    margin-bottom: 0;
  }
}

.search-recomendation {
  float: left;
  width: 100%;
  margin-top: 20px;
  p {
    width: 100%;
    float: left;
    text-align: left;
    margin: 0;
    font-family: $or;
    font-size: 16px;
    &:first-of-type {
      margin-bottom: 20px;
    }
  }
}

.search-warning-pic {
  float: left;
  position: relative;
  size: 80px;
  margin-right: 20px;
  img {
    size: 100%;
    object-fit: contain;
  }
	background-color: $bezh;
}
.ui-slider .ui-slider-handle{
	background-color: $green;
	cursor: pointer;
	outline: none;
	width: 11px;
	height: 12px;
	position: absolute;
	top: 11px;
	&::before{
		content: "";
		width: 0;
		height: 0;
		position: absolute;
		margin-top: -10px;
		border-left: 6px solid transparent;
		border-right: 5px solid transparent;
		border-bottom: 10px solid $green;
	}
}
.ui-slider-horizontal{
	cursor: pointer;
}
.title-none_ad{
	width: 100%;
	float: left;
	text-align: center;
	font-family: $ob;
	color: $green;
	margin: 40px 0;
}

.search-warning{
	display: block!important;
	width: 100%;
	float: left;
	box-sizing: border-box;
	padding: 20px;
	.average-ad-item-content{
		float: left;
		width: calc(100% - 101px);
		@include breakpoint(max-width 500px) {
			width: 100%;
		}
		.search-warning-title{
			font-family: $ob;
			text-align: left;
			color: $green;
			font-size: 24px;
    		line-height: 80px;
			@include breakpoint(max-width 1200px) {
				line-height: 26px;
			}
		}
	}
	.append-button{
		border: none;
		margin-top: 20px;
		margin-bottom: 0;
	}
}
.search-recomendation{
	float: left;
	width: 100% ;
	margin-top: 20px;
	p{
		width: 100%;
		float: left;
		text-align: left;
		margin: 0;
		font-family: $or;
		font-size: 16px;
		&:first-of-type{
			margin-bottom: 20px;
		}
	}
}
.search-warning-pic{
	float: left;
	position: relative;
	size: 80px;
	margin-right: 20px;
	img{
		size: 100%;
		object-fit: contain;
	}
}
