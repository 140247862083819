.sitemap{
  width: 100%;
  float: left;
  position: relative;
}

.map-content{
  width: 100%;
  float: right;
  position: relative;
  h1{
    width: 100%;
    float: left;
    font-family: $ob;
    font-size: 25px;
    color: #0d0101;
    text-align: left;
    margin-top: 20px;
    margin-bottom: 0;
    @include breakpoint(max-width 992px)
  }
  ul{
    width: 100%;
    float: left;
    position: relative;
    padding: 0;
    margin: 0;
    // display: -webkit-flex;
    // display: -ms-flex;
    // display: flex;
    // flex-flow: row wrap;
    // justify-content: flex-start;
  }
  .wrap-column{
    width: 25%;
    float: left;
    position: relative;
    @include breakpoint(max-width 992px){
      width: 50%;
    }
    @include breakpoint(max-width 550px){
      width: 100%;
    }
  }
  ul li{
    width: 100%;
    flex-basis: 25%;
    float: left;
    position: relative;
    margin: 5px 0;
    h2{
      a{
        display: inline-block;
        float: left;
        box-sizing: border-box;
        margin-right: 20px;
        padding: 4px 0;
        min-width: initial;
        color: $green;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
        font-size: 18px;
        font-family: $ob;
        line-height: 20px;
        &:hover{
          text-decoration: underline;
          color: #0d0101;

        }
      }
    }
  }

  ul ul{
    margin-top: 15px;
  }

  ul ul li{
    width: 100%;
    float: left;
    margin: 10px 0;
    flex-basis: 100%;
    a{
      color: #0d0101;
      font-size: 14px;
      font-family: $ob;
      text-align: left;
      background-color: #fff;
      text-transform: none;
      padding: 0;
      &:hover{
        text-decoration: underline;
        color: #0d0101;
        background: #fff;
      }
    }
  }

  ul ul ul{
    margin: 0;
  }

  ul ul ul li{
    margin: 0;
    padding-left: 7px;
    box-sizing: border-box;
    a{
      color: #0d0101;
      font-size: 12px;
      font-family: $or;
      text-align: left;
      background-color: #fff;
      &:hover{
        text-decoration: underline;
        color: #0d0101;
        background: #fff;
      }
    }
  }
}
