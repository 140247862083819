.kabinet-sender-avatar {
  display: block;
  position: relative;
  float: left;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 9px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.kabinet-sender-name {
  display: block;
  position: relative;
  float: left;
  width: calc(100% - (35px + 9px + 20px + 10px));
  padding-right: 20px;
  @include box-sizing(border-box);
  color: #4ea7c5;
  font-family: $ob;
  font-size: 18px;
  line-height: 34px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.kabinet-sender-messenges{
  display: block;
  position: relative;
  float: left;
  width: 20px;
  height: 20px;
  margin-top: 7px;
  margin-right: 10px;
  background-color: #334a51;
  border-radius: 50%;
  text-align: center;
  color: #ffffff;
  font-family: $ob;
  font-size: 13px;
  line-height: 20px;
}

.kabinet-msg-box {
  display: block;
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  padding-right: 32px;
  padding-left: 32px;
  padding-bottom: 0;
  @include box-sizing(border-box);
  @include breakpoint(max-width 500px) {
    padding-right: 10px;
    padding-left: 10px;
  }
  &__question {
    display: block;
    position: relative;
    float: left;
    width: 100%;
    margin-right: 0;
    margin-top: 10px;
    margin-bottom: 30px;
    padding-right: 15%;
    @include box-sizing(border-box);
    @include breakpoint(max-width 500px) {
      padding-right: 0;
    }
    p {
      display: block;
      position: relative;
      float: left;
      margin: 0;
      width: calc(100% - (89px + 14px));
      background-color: #4ea7c5;
      padding: 11px;
      @include box-sizing(border-box);
      border-radius: 7px;
      color: white;
      font-family: $or;
      font-size: 14px;
      @include breakpoint(max-width 500px) {
        width: 100%;
        margin-top: 20px;
      }
      &:after {
        display: block;
        position: absolute;
        content: '';
        top: 8px;
        left: -10px;
        width: 0;
        height: 0;
        border-top: 5px solid transparent;
        border-right: 10px solid #4ea7c5;
        border-bottom: 5px solid transparent;
        @include breakpoint(max-width 500px) {
          left: 18px;
          top: -14px;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-bottom: 10px solid #4ea7c5;
        }
      }
    }
    a {
      display: block;
      position: relative;
      float: left;
      width: 44px;
      height: 44px;
      margin-right: 14px;
      border-radius: 50%;
      overflow: hidden;
      background-color: #4a43a8;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  &__answer {
    display: block;
    position: relative;
    float: left;
    width: 100%;
    margin-right: 0;
    margin-top: 10px;
    margin-bottom: 30px;
    padding-left: 15%;
    @include box-sizing(border-box);
    @include breakpoint(max-width 500px) {
      padding-left: 0;
      display: flex;
      flex-direction: column;
    }
    p {
      display: block;
      position: relative;
      float: left;
      margin: 0;
      width: calc(100% - (89px + 14px));
      background-color: rgba(51, 74, 81, 0.7);
      padding: 11px;
      @include box-sizing(border-box);
      border-radius: 7px;
      color: white;
      font-family: $or;
      font-size: 14px;
      @include breakpoint(max-width 500px) {
        width: 100%;
        order:2;
        margin-top: 20px;
      }
      &:after {
        display: block;
        position: absolute;
        content: '';
        top: 8px;
        right: -10px;
        width: 0;
        height: 0;
        border-top: 5px solid transparent;
        border-left: 10px solid rgba(51, 74, 81, 0.7);
        border-bottom: 5px solid transparent;
        @include breakpoint(max-width 500px) {
          border-bottom: 10px solid rgba(51, 74, 81, 0.7);
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          right: 18px;
          top: -14px;
        }
      }
      .kabinet-msg-box__date{
        text-align: right;
      }
    }
    a {
      display: block;
      position: relative;
      float: left;
      width: 44px;
      height: 44px;
      margin-left: 14px;
      border-radius: 50%;
      overflow: hidden;
      background-color: #4a43a8;
      align-self: flex-end;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  &__date {
    display: block;
    position: absolute;
    width: 100%;
    bottom: -16px;
    left: 0;
    text-align: left;
    color: #838389;
    font-family: $opl;
    font-size: 10px;
  }
}

.kabinet-msg-wrapper {
  display: block;
  position: relative;
  float: left;
  width: 100%;
  height: 450px;
  overflow-y: scroll;
}
.kabinet-msg-form {
  display: block;
  position: relative;
  float: left;
  width: 100%;
  background-color: #fbfbfb;
  padding: 16px;
  @include box-sizing(border-box);
  textarea {
    display: block;
    position: relative;
    float: left;
    width: 100%;
    height: 95px;
    background-color: white;
    border: 1px solid #dedede;
    border-radius: 15px;
    resize: none;
    padding: 10px;
    @include box-sizing(border-box);
    &:focus {
      outline: none;
    }
  }
  &__notice{
    display: block;
    position: relative;
    float: left;
    width: 128px;
    margin-top: 26px;
    margin-left: calc(100% - (128px + 149px + 20px + 10px));
    margin-right: 10px;
    color: #334a51;
    font-family: $ob;
    font-size: 14px;
    line-height: 28px;
    @include breakpoint(max-width 500px) {
      width: 100%;
      margin: 10px 0;
      text-align: right;
    }
  }
  &__submit {
    display: block;
    position: relative;
    float: right;
    width: 149px;
    height: 28px;
    margin-top: 26px;
    margin-right: 20px;
    background-color: #334a51;
    border: 1px solid rgba(0, 0, 0, 0.16);
    border-radius: 20px;
    color: #fff;
    font-family: $ob;
    font-size: 13px;
    text-align: center;
    @include breakpoint(max-width 500px) {
      margin: 0;
    }
    &:focus {
      outline: none;
    }
    &:hover {
      background: linear-gradient(#626f7b, #334a51);
    }
  }
}
.kabinet-msg-box__blanket {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
  p{
    font-size: 22px;
    font-weight: 700;
    margin: 40px 0;
  }
}