
.support-search {
  width: 100%;
  float: left;
  display: block;
  position: relative;
  h1 {
    @include h1-title;
  }
}



.search-panel {
  width: 100%;
  float: left;
  display: block;
  position: relative;
  input{
    @include breakpoint(max-width 660px){
      width: 80%;
    }
  }
  .adsearch-button{
    @include breakpoint(max-width 660px){
      width: 20%;
    }
  }
}

.help-page__content {
  width: 100%;
  float: left;
  display: block;
  position: relative;
  text-align: center;
  &_help-category {
    width: 20%;
    float: left;
    display: block;
    position: relative;
    @include breakpoint(max-width 992px) {
      width: 100%;
    }
    .minus, .sub-menu {
      display: none;
    }
    .help-category-menu{
      display: none;
      position: relative;
      float: left;
      margin-bottom: 10px;
      @include breakpoint(max-width 992px){
        display: block;
        color: #000;

      }
      .fa{
        font-size: 10px;
        margin-right: 3px;
        display: inline-block;
        color: #000;
      }
    }
  }
  &_all {
    float: left;
    width: 80%;
    display: block;
    height: auto;
    border-left: 1px solid #dedede;
    box-sizing: border-box;
    @include breakpoint(max-width 992px) {
      width: 100%;
      border: none;
    }
    .breadcrumbs {
      float: left;
      width: 100%;
      position: relative;
      text-align: left;
      padding-left: 10px;
      &__list {
        list-style-type: none;
        float: left;
        padding: 0;
        li {
          display: inline-block;
          margin: 0 10px;
          position: relative;
          font-size: 12px;
          font-family: $or;
          a {
            color: #334a51;
          }
          &:first-of-type {
            margin-left: 0;
          }
          // &:before {
          // 	content: "";
          // 	position: absolute;
          // 	bottom: -10px;
          // 	left: 0;
          // 	width: 100%;
          // 	height: 0;
          // 	border-bottom: 2px dashed #ff6000;
          // }
          &:after {
            content: "/";
            position: absolute;
            right: -18px;
            bottom: 0;
          }
          &:last-of-type {
            line-height: 30px;
            a {
              float: right;
              line-height: 30px;
              font-size: 12px;
              color: #334a51;
              font-family: $or;
              padding-left: 20px;
              box-sizing: border-box;
            }
            &:after, &:before {
              content: none;
            }
          }
        }
      }
    }
    &-help-answer {
      width: 100%;
      float: left;
      position: relative;
      text-align: center;
      .help-answer_title {
        font-family: $or;
        font-size: 16px;
        color: #5c6c66;
        text-align: left;
        line-height: 37px;
        background-color: #fff4b8;
        padding-left: 30px;
        box-sizing: border-box;
        margin-top: 0;
      }
      .faq {
        width: calc(100% - 200px);
        float: left;
        position: relative;
        padding-left: 30px;
        box-sizing: border-box;
        @include breakpoint(max-width 992px){
          width: 65%;
        }
        @include breakpoint(max-width 660px){
          width: 100%;
        }
        span {
          // padding-left: 70px;
          box-sizing: border-box;
          text-align: left;
          font-family: $ob;
          font-size: 17px;
          color: #334a51;
          display: inline-block;
          float: left;
          width: 90%;
          margin: 0;
          margin-top: 10px;
        }
        p {
          font-size: 14px;
          width: 90%;
          font-family: $or;
          color: $green;
          text-align: left;
          display: inline-block;
          float: left;
          margin: 45px 0;
          line-height: 25px;
        }
        h4 {
          width: 100%;
          float: left;
          color: #334a51;
          font-size: 14px;
          font-family: $ob;
          padding-top: 15px;
          // padding-left: 30px;
          box-sizing: border-box;
          text-align: left;
        }
        .contact-us {
          background-color: $green;
          border: 1px solid rgba(0, 0, 0, 0.16);
          border-radius: 5px;
          display: inline-block;
          float: left;
          color: #fff;
          font-size: 13px;
          font-family: $ob;
          text-decoration: none;
          padding: 8px 25px;
          box-sizing: border-box;
          // margin-left: 70px;
          margin-top: 25px;
          margin-bottom: 25px;
          margin-left: 0;
          @include green-hov-rgba;
        }
        .help-article-form {
          width: 100%;
          float: left;
          position: relative;
          // padding-left: 30px;
          box-sizing: border-box;
          padding-bottom: 30px;
          box-sizing: border-box;
          p {
            // padding-left: 0;
            // margin-top: 0;
            // margin-bottom: 15px;
            box-sizing: border-box;
            text-align: left;
            font-family: $or;
            font-size: 12px;
            color: #334a51;
            display: inline-block;
            float: left;
            width: 90%;
            text-align: left;
            margin-top: 10px;
            margin-bottom: 0;
          }
          .row-form {
            width: 100%;
            float: left;
            display: inline-block;
            position: relative;
            text-align: left;
            label {
              width: 100%;
              float: left;
              color: #334a51;
              font-size: 12px;
              font-family: $or;
              float: left;
              text-align: left;
              display: inline-block;
              padding: 10px 0;
              box-sizing: border-box;
            }
            select, input {
              height: 29px;
              border: 0.3px solid #bbbbbc;
              display: inline-block;
              float: left;
              text-align: left;
              width: 301px;
              padding-left: 15px;
              box-sizing: border-box;
            }
            textarea {
              width: 501px;
              height: 98px;
              resize: none;
              float: left;
              display: inline-block;
              border: 0.3px solid #bbbbbc;
              padding: 15px;
              box-sizing: border-box;
            }
            .file_upload {
              display: block;
              position: relative;
              overflow: hidden;
              font-size: 1em;
              height: 58px;
              line-height: 40px;
              width: 20%;
              left: 0;
              bottom: 0;
              border-radius: 1px;
              &:last-of-type {
                margin-right: 0;
              }
            }
            .file_upload .button, .file_upload > mark {
              display: block;
              cursor: pointer;
            }

            .button {
              top: 10px;
              height: 30px;
              width: 40px;
              float: left;
              box-sizing: border-box;
              -moz-box-sizing: border-box;
              width: 100%;
              height: 100%;
              text-align: center;
              color: #fff;
              left: 0;
              font-size: 17px;
              /* font-family: $ql; */
              position: relative;
              background-image: url('../img/icon_camera.png');
              background-repeat: no-repeat;
            }
            .input-file {
              display: none;
            }
            input[type=file] {
              display: none;
            }
            .label-name {
              width: 38%;
            }
            .input-id {
              width: 188px;
            }
          }
          .contact-us {
            margin-left: 0;
            outline: none;
          }
        }
      }
      .help-answer_item {
        font-family: $ob;
        font-size: 12px;
        padding: 10px 0;
        box-sizing: border-box;
        width: 80%;
        float: left;
        text-align: left;
        color: #334a51;

      }
      .recent-article {
        float: right;
        position: relative;
        width: 200px;
        @include breakpoint(max-width 992px){
          width: 35%;
        }
        @include breakpoint(max-width 660px){
          width: 100%;
        }

      }
      .recent-article-item {
        width: 100%;
        float: left;
        background-color: #f3f3f3;
        box-shadow: 0px 0px 5px 1px rgba(6, 0, 0, .1);
        margin-bottom: 10px;
        &:first-of-type {
          margin-top: 15px;
        }
        h2 {
          color: #0e0101;
          font-family: $or;
          font-size: 15px;
          display: inline-block;
          float: left;
          width: 100%;
          text-align: center;
          padding: 20px 0;
          box-sizing: border-box;
          background-color: #fff;
          margin: 0;
          position: relative;
          &:after {
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            border-left: 9px solid transparent;
            border-right: 9px solid transparent;
            border-top: 10px solid #fff;
            bottom: -10px;
            left: 48%;
          }
        }
        .recent-article-item__articles {
          width: 100%;
          float: left;
          padding: 20px 20px 0 20px;
          box-sizing: border-box;
          a {
            width: 100%;
            text-align: left;
            float: left;
            display: inline-block;
            color: #334a51;
            font-size: 12px;
            font-family: $or;
            text-decoration: none;
            padding-bottom: 20px;
            box-sizing: border-box;
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
}

.help-page__content_help-category {
  padding-left: 5px;
  box-sizing: border-box;
  margin-top: 20px;
  margin-bottom: 20px;
  #cssmenu, #cssmenu ul, #cssmenu ul li, #cssmenu ul li a {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    display: block;
    line-height: 1;
    list-style: none outside none;
    margin: 0;
    padding: 0;
    position: relative;
  }
  #cssmenu {
    color: #ffffff;
    width: 100%;
   @include breakpoint(max-width 992px){
     display: flex;
     justify-content: flex-start;
     flex-wrap: wrap;
     align-items: center;
   }
    ul{
      @include breakpoint(max-width 992px){
        display: none;
      }
    }
  }
  #cssmenu ul ul {
    display: none;
  }
  #cssmenu > ul > li > a {
    color: #334a51;
    cursor: pointer;
    font-size: 14px;
    padding: 15px 0;
    text-decoration: none;
    z-index: 2;
    text-align: left;
    font-family: $ob;
    span {
      &:first-of-type {
        display: block;
        width: calc(100% - 25px);
      }
    }
    @include breakpoint(max-width 992px){
      padding: 10px 5px;
    }
  }
  #cssmenu > ul > li > a:hover,
  #cssmenu > ul > li.active > a,
  #cssmenu > ul > li:last-child > a,
  #cssmenu > ul > li.last > a {
    // border-bottom: 1px solid #1682ba;
  }
  .holder {
    height: 0;
    position: absolute;
    right: 0;
    top: 1px;
    width: 0;
  }
  .holder:after, .holder:before {
    content: "";
    display: block;
    height: 6px;
    position: absolute;
    right: 10px;
    -webkit-transform: rotate(-135deg);
    -moz-transform: rotate(-135deg);
    transform: rotate(-135deg);
    width: 6px;
    z-index: 10;
  }
  .holder:after {
    border-left: 2px solid transparent;
    border-top: 2px solid transparent;
    top: 17px;
    right: 10px;
  }
  #cssmenu > ul > li > a:hover > span:after,
  #cssmenu > ul > li.active > a > span:after,
  #cssmenu > ul > li.open > a > span:after {
    // border-color: #eeeeee;
  }
  .holder:before {
    border-left-color: inherit;
    border-left-style: solid;
    border-left-width: 2px;
    border-top-color: inherit;
    border-top-style: solid;
    border-top-width: 2px;
    top: 18px;
  }
  #cssmenu ul ul li a {
    // background:#49505a;
    // border-bottom: 1px solid #32373e;
    // border-left: 1px solid #32373e;
    // border-right: 1px solid #32373e;
    color: #334a51;
    cursor: pointer;
    font-size: 12px;
    padding: 10px 20px;
    text-decoration: none;
    z-index: 1;
    font-family: $ob;
    text-align: left;
  }
  #cssmenu ul ul li:hover > a,
  #cssmenu ul ul li.open > a,
  #cssmenu ul ul li.active > a {
    // background: none repeat scroll 0 0 #424852;
    // color: #ffffff;
  }
  #cssmenu ul ul li:first-child > a {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  #cssmenu ul ul ul li:first-child > a {
    -webkit-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.1) inset;
    box-shadow: 0 1px 0 rgba(255, 255, 255, 0.1) inset;
  }
  #cssmenu ul ul ul li a {
    padding-left: 30px;
  }
  #cssmenu > ul > li > ul > li:last-child > a,
  #cssmenu > ul > li > ul > li.last > a {
    border-bottom: 0 none;
  }
  #cssmenu > ul > li > ul > li.open:last-child > a,
  #cssmenu > ul > li > ul > li.last.open > a {
    // border-bottom: 1px solid #32373e;
  }
  #cssmenu > ul > li > ul > li.open:last-child > ul > li:last-child > a {
    border-bottom: 0 none;
  }
  #cssmenu ul ul li.has-sub > a:after {
    // border-left: 2px solid #eeeeee;
    // border-top: 2px solid #eeeeee;
    content: "";
    display: block;
    height: 5px;
    position: absolute;
    right: 20px;
    top: 11.5px;
    -webkit-transform: rotate(-135deg);
    -moz-transform: rotate(-135deg);
    transform: rotate(-135deg);
    width: 5px;
    z-index: 10;
  }
  #cssmenu ul ul li.active > a:after,
  #cssmenu ul ul li.open > a:after,
  #cssmenu ul ul li > a:hover:after {
    border-color: #ffffff;
  }
}
