.ad-error__title{
  width: 100%;
  float: left;
  position: relative;
  border-top: 1px solid #dedede;
  border-bottom: 1px solid #dedede;
  padding: 30px 0;
  box-sizing: border-box;
  h2{
    margin: 0;
    padding: 0;
    font-size: 30px;
    font-family: $ob;
    color: #000;

  }
}

.ad-error__content{
  width: 100%;
  float: left;
  position: relative;
  &_left{
    width: 75%;
    float: left;
    position: relative;
    padding: 20px 0;
    box-sizing: border-box;
    h2{
      font-size: 14px;
      font-family: $ob;
      padding: 0;
      margin: 0;
    }
    p{
      font-size: 14px;
      font-family: $osb;
      text-decoration: underline;
      float: left;
      display: inline-block;
      margin: 0 60px;
      min-width: 15%;
      padding: 25px 0;
      box-sizing: border-box;
    }
    .explanation{
      background-color: $bezh;
      padding: 30px 0;
      box-sizing: border-box;
      text-align: center;
      float: left;
      width: 100%;
      p{
        display: inline-block;
        font-size: 14px;
        font-family: $ob;
        margin: 0;
        color: #000;
        text-decoration: none;
        padding: 0;
        float: none;
      }
    }
    .write-admin{
      margin: 30px 0;
      width: 100%;
      display: inline-block;
      float: left;
      border: 1px solid #b5b5b5;
      border-bottom-right-radius: 14px;
      border-top-right-radius: 14px;
      padding: 10px 0;
      box-sizing: border-box;
      border-left: none;
      text-align: center;
    }
    .write-admin-button{
      display: inline-block;
      font-size: 12px;
      font-family: $osb;
      color: #010a0c;
      text-decoration: none;
      float: none;
    }
    .red-mail{
      display: inline-block;
      size: 20px 13px;
      float: left;
      margin-right: 5px;
      background-image: url("../img/red-mail.png");
      background-repeat: no-repeat;
      position: relative;
      top: 2px;
    }
    .org{
      h2{
        font-family: $ob;
        font-size: 16px;
        color: #000;
        padding: 20px 0;
        box-sizing: border-box;
      }
      width: 100%;
      .org-items{
        width: 16%;
      }
    }
  }
  &_right{
    width: 25%;
    float: left;
    padding-top: 60px;
    box-sizing: border-box;
    .banner-rightbar{
      float: right;
    }
  }
}
