.link{
  padding-left: 50px;
  box-sizing: border-box;
  a{
    width: 100%;
    display: inline-block;
    margin: 5px 0;
    float: left;
  }
}
