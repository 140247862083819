.vhod{
  float: left;
  width: 100%;
  display: block;
  .container {
    display: flex;
    margin-top: 50px;
  }
  .problem-vhod{
    width: 100%;
    float: left;
    position: relative;
    display: inline-block;
    .checkbox0{
      float: left;
      width: 6%;
    }
    p{
      color: #424242;
      font-size: 12px;
      font-family: $or;
      float: left;
      width: 40%;
      margin-bottom: 0;
      margin-top: 5px;
    }
    a{
      display: inline-block;
      float: right;
      text-align: right;
      color: #424242;
      font-size: 12px;
      margin-top: 5px;
      padding-right: 5px;
      box-sizing: border-box;
      font-family: $or;

    }
  }
  @include breakpoint(max-width 770px){
    text-align: center;
  }
}
