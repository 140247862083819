
.home-content, .home-content-organization{
	float: left;
	width: 100%;
	display: block;
	text-align: center;
	position: relative;
}

.home-contents, .row{
	float: left;
	width: 100%;
	display: flex;
	-webkit-flex-wrap: wrap;
	    -ms-flex-wrap: wrap;
	        flex-wrap: wrap;

	flex-direction: row;

	.row{
		padding-top: 35px;
		margin-left: 0;
		margin-right: 0;
		&:first-of-type{
			padding-top: 0;
		}
		@include breakpoint(max-width 670px){
			width: 50%;
			float: left;
			padding-top: 0;
		}
	}
	.text-about{
		display: none;
		background-color: #f4f3f3;
		border-radius: 15px;
		width: 100%;
		font-size: 14px;
		font-family: $or;
		text-align: left;
		position: relative;
		padding: 25px 50px;
		box-sizing: border-box;
		margin: 10px 0;
		@include breakpoint(max-width 992px) {
			padding: 25px;
		}

		.text-about-title{
			width: 100%;
			font-size: 16px;
			font-family: $osb;
			color: #006b93;
			display: inline-block;
			border-bottom: 2px dashed #d1d1d1;
			margin-bottom: 15px;
			a{
				font-style: normal;
				font-family: $or;
				text-decoration: none;
				padding-bottom: 15px;
				box-sizing: border-box;
				display: inline-block;
				&:hover{
					text-decoration: underline;
				}
			}
			b{
				font-style: italic;
			}
		}
	}
	.text-about-links{
		width: 100%;
		display: inline-block;
		margin: 0 auto;
		column-count: 3;
		//column-width: 33%;
		column-gap: 10px;
		@include breakpoint(max-width 992px) {
			column-count: 2;
		}
		@include breakpoint(max-width 600px) {
			column-count: 1;
		}
	}
	.text-about-link{
		color: #006b93;
		font-size: 14px;
		font-family: $or;
		text-align: left;
		text-decoration: none;
		width: 100%;
		display: inline-block;
		&:hover{
			text-decoration: underline;
		}
		@include breakpoint(max-width 600px) {
			text-align: center;
		}
	}
}
.home-contents{
	margin: 0 auto;
	float: none;
	width: 90%;
}
//.text-about:after{
//
//		content: "";
//		position: absolute;
//		width: 0;
//		height: 0;
//		border-left: 9px solid transparent;
//    border-right: 9px solid transparent;
//    border-bottom: 14px solid #f4f3f3;
//    display: inline-block;
//    z-index: 4;
//    top: -13px;
//    // left: 7%;
//}

.home-content-item{
	-webkit-flex-basis: 20%;
	        flex-basis: 20%;
	display: flex;
	width: 50%;
	position: relative;
	@include breakpoint(max-width 770px){
		width: 20%;
    float: left;
    display: inline-block;
	}
	@include breakpoint(max-width 670px){
		width: 100%;
		flex-basis: 100%;
    float: left;
    display: inline-block;
	}
}

//.home-content-organization .home-content-item{
//	-webkit-flex-basis: 20%;
//		flex-basis: 20%;
//	display: flex;
//	position: relative;
//	@include breakpoint(max-width 770px){
//		width: 20%;
//		float: left;
//		display: inline-block;
//	}
//	@include breakpoint(max-width 670px){
//		width: 100%;
//		flex-basis: 100%;
//    float: left;
//    display: inline-block;
//	}
//	.content-item-thumb{
//		//size: 44px;
//		//overflow: hidden;
//		//margin-top: 10px;
//		//img{
//		//	object-fit: cover;
//		//	// height: 100%;
//		//	// width: auto;
//		//}
//		@include breakpoint(max-width 770px){
//			float: none;
//    	margin: 0 auto;
//		}
//	}
//	.content-item-text{
//    width: initial;
//		padding-left: 1px;
//		display: flex;
//		flex-direction: row;
//    	align-items: center;
//		justify-content: flex-start;
//		height: 44px;
//		@include breakpoint(max-width 1200px) {
//			align-items: flex-start;
//		}
//	}
//}
//.home-content-organization .home-content-item:last-of-type{
//	margin-right: 0;
//}
.content-item-thumb{
	display: block;
	position: relative;
	width: 72px;
	height: 55px;
	float: left;
	img{
		width: 40px;
		height: 40px;
		position: absolute;
		object-fit: scale-down;
	    top: 50%;
	    left: 50%;
	    -webkit-transform: translate(-50%, -50%);
	       -moz-transform: translate(-50%, -50%);
	        -ms-transform: translate(-50%, -50%);
	         -o-transform: translate(-50%, -50%);
	            transform: translate(-50%, -50%);
	}
	//@include breakpoint(max-width 770px){
	//	float: none;
	//	margin: 0 auto;
	//}
}
.content-item-text{
	width: initial;
	padding-left: 1px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	height: 44px;
	.text-title{
		padding-bottom: 0;
		text-align: left;
	}
}
.text-title{
	color: #0a0101;
	font-size: 16px;
	font-family: $osb;
	text-align: left;
	display: block;
	padding-bottom: 10px;

	&:hover{
		text-decoration: underline;
	}
	@include breakpoint(max-width 770px){
		 width: 100%;
		 text-align: center;
		 font-size: 13px;
	}
}


.home-slider, .home-slider-organization, .home-slider-specials{
	width: 100%;
	float: left;
	display: inline-block;
	text-align: center;
	position: relative;
	background-color: #efefef;
	padding: 30px 0;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	h3{
		font-size: 16px;
		text-align: left;
		font-family: $osb;
		margin-bottom: 20px;
		color: #000;
		margin-top: 0;
		width: 50%;
		float: left;
		text-transform: none;
		display: inline-block;
		z-index: 2;
		position: relative;
	}
	a{
		float: right;
		font-size: 16px;
		font-family: $opl;
		color: #006b93;
		display: inline-block;
		font-style: italic;
		text-align: right;
		position: relative;
	 	top: -3px;
		//z-index: 100;
	}
	.owl-carousel{
		width: 94%;
		float: none;
		margin: 0 auto;
		@include breakpoint(max-width 800px) {
			.owl-controls {
				display: none;
			}
		}
	}
}

.white-slider{
	background-color: #fff;
}

.home-slider{
	margin-top: 35px;
	box-sizing: border-box;
	.vip-circle{
		background-color: #ffa000;
	box-shadow: 0 4px 12px rgba(0, 0, 0, 0.13);
		size: 44px;
		display: inline-block;
		position: absolute;
		text-transform: uppercase;
		color: #120202;
		font-size: 14px;
		font-family: $ob;
		border-radius: 50%;
		z-index: 1800;
		text-align: center;
		top: 0%;
		right: -11px;
		line-height: 44px;
		@include breakpoint(max-width 670px){
			right: 1px;
		}
	}
	.slide-link{
		img{
			margin-top: 23px;
		}
	}
}
.home-slider-organization{
	background-color: #fff;
}
.home-slider-specials{
	.slide{

		position: relative;
		.yellow-circle{
			position: absolute;
			size: 56px;
			border-radius: 50%;
			background-color: #ffa000;
			font-family: $ob;
			color: #120202;
			font-size: 16px;
			z-index: 5;
	    margin-top: 3px;
	    left: 80%;
			line-height: 56px;
			text-align: center;
			@include breakpoint(max-width 992px) {
				left: 0;
			}
		}
		.thumb{
			width: 100%;
			float: left;
			position: relative;
			height: 141px;
			margin-top: 25px;
			img{
				size: 100%;
			}
			p{
				width: 100%;
				bottom: 0;
				left: 0;
				position: absolute;
				display: inline-block;
				margin: 0;
				background-color: rgba(0,0,0,0.75);
				font-size: 14px;
				color: #fff;
				font-family: $or;
				padding: 15px;
				box-sizing: border-box;
			}
		}
		h2{
			font-size: 14px;
			font-family: $ob;
			color: #000;
			width: 100%;
			display: inline-block;
			margin: 0;
			padding: 10px;
			text-align: left;
			border-bottom: 2px solid #d2d1d1;
			box-sizing: border-box;
			border-left: 0.625px solid #898989;
			border-right: 0.625px solid #898989;
		}
		.price{
			display: inline-block;
			width: 100%;
			float: left;
			font-size: 14px;
			color: #000;
			font-family: $opi;
			text-decoration: line-through;
			padding: 5px 10px;
			box-sizing: border-box;
			line-height: 12px;
			border-left: 0.625px solid #898989;
			border-right: 0.625px solid #898989;
			border-bottom: 0.625px solid #898989;
			.yellow-ellipse{
				float: right;
				display: inline-block;
				background-color: #ffd800;
				border-radius: 50px;
				color: #334a51;
				font-family: $ob;
				padding: 5px 20px;
				box-sizing: border-box;
			}
		}
	}
	.owl-controls .owl-nav .owl-next, .owl-controls .owl-nav .owl-prev {
    top: 125px;
		@include breakpoint(max-width 670px){
			display: none;
		}
	}
}
.owl-model{
	width: 100%;
	float: none;
	display: inline-block;
	margin: 0 auto;
	position: relative;
	margin-bottom: 20px;
}
.owl-stage-outer{
	width: 100%;
}
.owl-controls {
display: block;
	.owl-nav{
		position: absolute;
	    top: 0;
	    width: 100%;
	    .owl-prev{
	    	background-image: url("../img/left-arrow.png");
		    left: -40px;
	    }
	    .owl-next{
		    right: -40px;
		    background-image: url("../img/right-arrow.png");
	    }
	    .owl-prev,.owl-next{
	    	width: 32px;
		    height: 32px;
		    position: absolute;
		    top: 80px;
		    padding: 0;
		    background-repeat: no-repeat;
	    }
	}
	@include breakpoint(max-width 670px){
		display: none;
	}
}
.slide{
	float: left;
	width: 100%;
	display: block;
	p{
		font-family: $ob;
		font-size: 14px;
		text-align: left;
		float: left;
		display: inline-block;
		color: #000;
		margin: 0;
		.rubl-icon, .rubl-icon svg {
		    display: inline-block;
		    height: 11px;
		    width: 16px;
		    top: -2px;
		}
	}
}
.slide-link{
	width: 100%;
	img{
		height: 110px;
		width: 100%;
		object-fit: cover;
	}
	h4{
		font-size: 14px;
		font-family: $ob;
		color: #006b93;
		text-align: left;
		margin-bottom: 5px;
		line-height : 1.4;
		overflow : hidden;
		height: 35px;
	}
}
.new-organization{
	width: 100%;
	float: left;
	display: block;
	padding: 30px 0;
	text-align: center;
	position: relative;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	h3{
		font-size: 16px;
		text-align: left;
		font-family: $osb;
		margin-bottom: 20px;
		margin-top: 0;
		width: 50%;
		float: left;
		display: inline-block;
	}
	a{
		float: right;
		font-size: 16px;
		font-family: $opl;
		color: #000;
		display: inline-block;
		margin-bottom: 20px;
		text-align: right;
		width: 50%;
	}
}
.org{
	float: none;
	margin: 0 auto;
	width: 90%;
	display: inline-block;
}
.org-items{
	float: left;
	width: 13.3%;
	display: inline-block;
	margin-left: 42px;
	.slide-link{
		width: 100%;
		float: left;
		display: block;
	}
	&:first-of-type{
		margin-left: 0;
	}
}
.specials{
	width: 100%;
	float: left;
	display: inline-block;
	text-align: center;
	position: relative;
	background-color: #FFF6C9;
	padding: 30px 0;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
	h3{
		font-size: 16px;
		text-align: left;
		font-family: $osb;
		margin-bottom: 20px;
		margin-top: 0;
		width: 50%;
		float: left;
		display: inline-block;
	}
	a{
		float: right;
		font-size: 16px;
		font-family: $opl;
		color: #000;
		display: inline-block;
		margin-bottom: 20px;
		text-align: right;
		width: 50%;
	}
}
.specials-row{
	float: none;
	margin: 0 auto;
	width: 90%;
}
.specials-items{
	width: 24%;
	float: left;
	display: inline-block;
	border: 4px solid transparent;
	margin-right: 10px;
	&:hover{
		border: 4px solid #FFD800;
		.vip{
			display: block;
			position: absolute;

		}
		.offer{
			display: block;
		}
	}
	&:last-of-type{
		margin-right: 0;
	}

}
.percent{
	top: 0;
	display: block;
	position: absolute;
	width: 45px;
	height: 20px;
	float: left;
	left: 15px;
	font-size: 15px;
	font-family: $hbl;
	text-align: center;
	line-height: 20px;
	color: #FFF;
	background-color: #000;
	&:before{
		content: "";
		width: 0;
		height: 0;
		border-left: 22px solid transparent;
		border-right: 22px solid transparent;
		border-top: 15px solid #000;
		position: absolute;
		bottom: -15px;
		left: 0;
	}
}
.vip{
	top: 0;
	display: none;
	z-index: 10;
	position: absolute;
	width: 45px;
	height: 20px;
	float: right;
	background-color: #FFD800;
	right: 15px;
	font-size: 15px;
	font-family: $hbl;
	text-align: center;
	line-height: 20px;
	&:before{
		content: "";
		width: 0;
		height: 0;
		border-left: 22px solid transparent;
		border-right: 22px solid transparent;
		border-top: 15px solid #FFD800;
		position: absolute;
		bottom: -15px;
		left: 0;
	}
}
.offer{
	width: 100%;
	height: 65%;
	float: left;
	display: none;
	position: absolute;
	z-index: 10;
	background-color: rgba(0, 0, 0, 0.75);
	padding: 10px 0px 10px 10px;
	bottom: 0;
	p{
		font-size: 15px;
		text-align: left;
		font-family: $hl;
		color: #FFF;
		margin-bottom: 5px;
	}
	.more-offer{
		display: inline-block;
		font-size: 15px;
		font-family: $hl;
		color: #000;
		text-transform: uppercase;
		text-align: center;
		width: 50%;
		background-color: #debd2a;
		border-radius: 2px;
		float: left;
		padding: 0 10px;
		-webkit-box-sizing: border-box;
		   -moz-box-sizing: border-box;
		        box-sizing: border-box;
	}
}

.specials-items-thumb{
	width: 100%;
	height: 170px;
	position: relative;
	text-align: center;
	display: block;
	@include overflow-img;
	background-color: #FFF;
	img{
		max-width: 150%;
	}
	}
.specials-items-title{
	width: 100%;
	float: left;
	display: block;
	background-color: #FFF;
	height: 40px;
	h3{
		width: 100%;
		font-size: 13px;
		font-family: $hl;
		margin: 0;
		padding: 5px 0 5px 5px;
		border-bottom: 1px solid #d1d1d1;
	}
	p{
		width: 100%;
		float: left;
		display: inline-block;
		height: 16px;
		font-size: 10px;
		font-family: $hl;
		text-align: left;
		padding-left: 15px;
		text-decoration: line-through;
		line-height: 16px;
		margin: 0;
		background-color: #FFF;

	}
}
.yellow-triangle{
	display: block;
    width: 40%;
    float: right;
 	background-color: #FFD800;
    font-size: 12px;
    font-family: $hbl;
    position: relative;
    text-align: right;
    padding-right: 10px;
    height: 16px;
    &:before{
    	content: "";
    	display: block;
    	position: absolute;
    	width: 0;
		height: 0;
		border-top: 16px solid #FFD800;
		border-left: 16px solid transparent;
		left: -15px;
		float: left;
    }

}
