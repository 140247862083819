.kabinet-setting-account{
  width: 100%;
  float: left;
  position: relative;
  &__left{
    width: 20%;
    float: left;
    position: relative;
    padding-top: 70px;
    padding-bottom: 50px;
    box-sizing: border-box;
    @include breakpoint(max-width 770px) {
      display: none;
    }
    ul{
      width: 100%;
      float: left;
      display: inline-block;
      padding: 0;
      margin: 0;
      li{
        width: 70%;
        display: inline-block;
        list-style-type: none;
        margin: 5px 0;
        a{
          text-decoration: none;
          font-size: 12px;
          font-family: $ob;
          color: $green;
          &:hover{
            text-decoration: underline;
          }
        }
      }
    }
  }
  &__right{
    width: 80%;
    float: left;
    position: relative;
    padding-top: 10px;
    padding-left: 50px;
    padding-bottom: 50px;
    box-sizing: border-box;
    border-left: 1px solid #dedede;
    @include breakpoint(max-width 770px) {
      width: 100%;
      padding-left: 0;
      border-left: none;
    }
    h2{
      font-size: 14px;
      font-family: $ob;
      text-align: left;
      margin: 0;
      padding: 5px 0 45px;
      color: #c1a72d;
    }
    p{
      display: inline-block;
      margin: 0;
      font-family: $or;
      font-size: 13px;
      color: #6c7274;
      a{
        text-decoration: underline;
        color: #000;
        font-family: $ob;
      }
    }
    .setting-account-form{
      width: 50%;
      float: left;
      position: relative;
      @include breakpoint(max-width 992px) {
        width: 100%;
      }
      h2{
        font-size: 14px;
        font-family: $ob;
        text-align: left;
        margin: 0;
        padding: 5px 0 45px;
        color: #c1a72d;
      }
      .row-form{
        display: flex;
        width: 100%;
        float: left;
        margin: 15px 0;
        position: relative;
        @include breakpoint(max-width 770px) {
          flex-direction: column;
          align-items: center;
          width: 60%;
          min-width: 300px;
          float: none;
          margin: 15px auto;
        }
        label{
          width: 45%;
          min-width: 200px;
          float: left;
          display: inline-block;
          font-size: 13px;
          font-family: $or;
          color: #9b9fa0;
          text-align: right;
          font-weight: 500;
          padding-right: 45px;
          box-sizing: border-box;
          @include breakpoint(max-width 770px) {
            width: auto;
            padding: 0;
            text-align: center;
          }
        }
        input{
          width: 55%;
          float: left;
          outline: none;
          height: 30px;
          padding-left: 10px;
          box-sizing: border-box;
          min-width: 250px;
        }
        .file_upload{
          margin: 0;
          .button{
            top: 0;
          }
        }
        .memo-error{
          top: 0;
          @include breakpoint(max-width 1200px) {
            top: -130%;
            right: 0;
            &:after {
              border-right: 9px solid transparent;
              border-top: 13px solid #c91414;
              border-left: 9px solid transparent;
              top: 100%;
              right: 10px;
              left: auto;
            }
          }
          @include breakpoint(max-width 770px) {
            top: -40px;
            right: auto;
            left: 0;
          }
        }
      }
      .row-knopka{
        width: 55%;
        float: right;
        @include breakpoint(max-width 770px) {
          margin: 10px auto;
          float: none;
          display: block;
        }
      }
    }
    .row-soc{
      width: 58%;
      float: left;
      display: inline-block;
      margin: 7px 0;
      @include breakpoint(max-width 500px) {
        width: 100%;
        display: flex;
        justify-content: space-around;
      }
      a{
        min-width: 149px;
        display: inline-block;
        background-color: $bezh;
        float: right;
        border-radius: 8px;
        border: 1px solid #dedede;
        text-align: center;
        height: 30px;
        line-height: 26px;
        color: #334a51;
        font-size: 13px;
        font-family: $ob;
        text-decoration: none;
        position: relative;
        margin-top: 7px;
        @include green-hov-rgba;
      }
      span{
        size: 37px;
        display: inline-block;
        position: relative;
        float: left;
      }
      .vk-soc-37{
        background-image: url("../img/vk-soc-37.png");
      }
      .gp-soc-37{
        background-image: url("../img/gp-soc-37.png");
      }
      .fb-soc-37{
        background-image: url("../img/fb-soc-37.png");
      }
      .twi-soc-37{
        background-image: url("../img/twi-soc-37.png");
      }
    }
  }
}
