.kabinet-history{
  width: 100%;
  float: left;
  position: relative;
  &__left{
    width: 20%;
    float: left;
    position: relative;
    padding-top: 70px;
    padding-bottom: 50px;
    box-sizing: border-box;
    @include breakpoint(max-width 992px){
      width: 100%;
      padding: 15px 0;
    }
    ul{
      width: 100%;
      float: left;
      display: inline-block;
      padding: 0;
      margin: 0;
      @include breakpoint(max-width 992px){
        display: flex;
      }
      li{
        width: 100%;
        display: inline-block;
        list-style-type: none;
        margin: 5px 0;
        a{
          text-decoration: none;
          font-size: 12px;
          font-family: $ob;
          color: $green;
          &:hover{
            text-decoration: underline;
          }
          .count{
            padding-left: 10px;
            box-sizing: border-box;
          }
        }
      }
      li.active{
        a{
          color: #334a51;
        }
      }
    }
  }
  &__right{
    width: 80%;
    float: left;
    position: relative;
    padding-top: 70px;
    padding-bottom: 50px;
    box-sizing: border-box;
    border-left: 1px solid #dedede;
    @include breakpoint(max-width 992px){
      width: 100%;
      border: none;
      padding: 15px 0;
      padding-top: 0;
    }
    h2{
      padding-left: 50px;
      box-sizing: border-box;
      font-family: $or;
      font-size: 21px;
      color: #000;
      margin: 0;
      display: inline-block;
      @include breakpoint(max-width 992px){
        padding-left: 0;
      }
    }
    form{
      display: flex;
      flex-direction: column;
      width: 319px;
      padding-left: 50px;
      margin-top: 15px;
      input{
        outline: none;
        width: 100%;
        height: 38px;
        padding-left: 10px;
        background-color: #fff;
        border: 1px solid hsla(0,0%,61%,.38);
        margin-bottom: 15px;
        border-radius: 4px;
      }
      button{
        text-decoration: none;
        display: inline-block;
        min-width: 50%;
        height: 31px;
        font-size: 13px;
        line-height: 31px;
        background-color: #334a51;
        border: none;
        border-radius: 15px;
        text-transform: uppercase;
        color: #fff;
        position: relative;
        z-index: 11;
        outline: none;
      }
    }
    .kabinet-history__right-nav{
      padding: 25px 0 25px 50px;
      box-sizing: border-box;
      float: left;
      width: 100%;
      @include breakpoint(max-width 992px){
        padding: 0 0 15px 0;
      }
      ul{
        padding-top: 10px;
        box-sizing: border-box;
        width: 100%;
        float: left;
        display: inline-block;
        position: relative;
        margin: 0;
        padding-left: 0;
        @include breakpoint(max-width 992px){
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
        }
        li{
          width: 20%;
          display: inline-block;
          list-style-type: none;
          position: relative;
          @include breakpoint(max-width 992px){
            width: auto;
          }
          a{
            text-decoration: none;
            color: #1a97bb;
            font-size: 14px;
            font-family: $ob;
            span{
              color: #adadad;
            }
          }
        }
        li.active{
          a{
            color: #000;
          }
        }
      }
    }
    .checkbox0{
      width: 50%;
      float: left;
      padding-left: 50px;
      box-sizing: border-box;
      label{
        float: left;
      }
      p{
        margin: 0;
        font-family: $or;
        font-size: 14px;
        color: #000;
        display: inline-block;
        float: left;
        line-height: 26px;
      }
      input[type=checkbox]:checked + label{
        background-image: url('../img/checkbox-sprite.png');
        background-repeat: no-repeat;
        background-position: 0px -27px;
        display: block;
        size: 21px;
        text-align: right;
        margin-top: 0;
        float: left;
      }
    }
    .table-block{
      width: 100%;
      float: left;
      position: relative;
      @include breakpoint(max-width 992px){
        overflow-x: scroll;
      }
      table{
        width: 100%;
        min-width: 730px;
        tr{

        }
        tr:first-of-type{
          background-color: #334a51;
        }
        th{
          font-size: 14px;
          font-family: $ob;
          color: #fff;
          padding: 10px 0;
          box-sizing: border-box;
          a{
            font-size: 14px;
            font-family: $ob;
            color: #fff;
            padding: 10px 0;
            box-sizing: border-box;
            display: inline-block;
          }
          .rubl-icon{
            svg{
              fill: #fff;
              height: 11px;
            }
          }
        }
        td{
          padding: 10px 0;
          box-sizing: border-box;
          font-family: $ob;
          font-size: 12px;
          color: #000;
          .empty{
            padding: 10px 0;
            box-sizing: border-box;
            font-family: $ob;
            font-size: 12px;
            color: #000;
          }
          .rubl-icon{
            top: -1px;
            left: -4px;
            svg{
              height: 10px;
            }
          }
        }
        th:first-of-type{
          padding-left: 40px;
          box-sizing: border-box;
        }
        td:first-of-type{
          padding-left: 40px;
          box-sizing: border-box;
        }
        .element-table{
          size: 8px;
          display: inline-block;
          background-image: url("../img/element-table.png");
        }
      }
    }
  }
}
