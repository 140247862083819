.all-news{
  width: 100%;
  float: left;
  position: relative;
  padding-bottom: 30px;
  box-sizing: border-box;
  text-align: center;
  background-color: #edeef0;
  @include breakpoint(max-width 992px){
    padding-bottom: 0;
  }
  h1{
    width: 100%;
    float: left;
    font-family: $ob;
    font-size: 25px;
    color: #0d0101;
    text-align: left;
    margin: 20px 0;
    text-transform: uppercase;
  }
}

.all-news__item{
  width: 30.333%;
  height: auto;
  position: relative;
  background-color: #fff;
  border-radius: 5px;
  display: inline-block;
  float: none;
  margin: 1%;
  min-height: 527px;
  @include breakpoint(max-width 992px){
    width: 47%;
  }
  @include breakpoint(max-width 660px){
    width: 100%;
    margin: 0;
  }
  span{
    width: 100%;
    float: left;
    text-align: left;
    color: $green;
    font-family: $ob;
    font-size: 18px;
    padding: 20px 30px;
    box-sizing: border-box;
    &:before{
      content: "";
      height: 15px;
      width: 2px;
      background-color: $green;
      display: block;
      float: left;
      margin-right: 15px;
      position: relative;
      top: 5px;
    }
  }
  .thumb{
    width: 100%;
    float: left;
    position: relative;
    height: 200px;
    overflow: hidden;
    img{
      size: 100%;
      object-fit: cover;
    }
  }
  .content{
    width: 100%;
    float: left;
    position: relative;
    text-align: left;
    padding: 30px;
    box-sizing: border-box;
    @include breakpoint(max-width 992px){
      padding: 15px;
    }
    h2{
      font-size: 18px;
      font-family: $ob;
      line-height: 20px;
      width: 100%;
      float: left;
      text-align: left;
      margin: 0 0 15px;
    }
    p{
      margin: 0 0 25px;
      width: 100%;
      float: left;
      font-size: 14px;
      color: $green;
    }
    a{
      text-transform: uppercase;
      display: inline-block;
      float: right;
      color: $green;
      font-family: $ob;
      font-size: 16px;
      &:after{
        content: "";
        background: url("../img/news/arrow-next.png") no-repeat;
        size: 5px 9px;
        display: block;
        position: relative;
        margin-left: 5px;
        background-size: contain;
        top: 7px;
        float: right;
      }
    }
  }
}
