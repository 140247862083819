.shops-header__menu {
  width: 100%;
  float: left;
  position: relative;
  text-align: center;
  background-color: #334a51;
}

.shops-header__mnu {
  width: 100%;
  padding: 10px 0 0;
  box-sizing: border-box;
  display: inline-block;
  float: left;
  text-align: left;
  margin: 0;
  li {
    display: inline-block;
    list-style-type: none;
    min-width: 30px;
    margin: 0 20px;
    padding-bottom: 18px;
    box-sizing: border-box;
    position: relative;
    @include breakpoint(max-width 500px) {
      margin: 0 8px;
    }
    a {
      text-decoration: underline;
      color: #fff;
      font-family: $ob;
      font-size: 14px;
      width: 100%;
      display: inline-block;
      position: relative;
      .icon {
        background-repeat: no-repeat;
        // background-size: cover;
        position: relative;
        margin-right: 5px;
        display: inline-block;
      }
    }
    &:first-of-type {
      margin-left: 0;
    }
  }
  li.active {
    // a{
    //   border-bottom: 3px solid $bezh;
    // }
    a::after {
      content: "";
      position: absolute;
      height: 4px;
      width: 100%;
      left: 0;
      background-color: #ffd800;
      display: inline-block;
      top: 42px;
    }
    // a{
    //   color: #ffdf2d;
    //   text-decoration: none;
    // }
  }
}

.shops-ad, .shops-favorite, .shops-msg, .shops-wallet, .shops-setting, .shops-my {
  display: inline-block;
  width: 26px;
  height: 23px;
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
  top: 6px;
  margin-right: 5px;
  @include breakpoint(max-width 992px) {
    width: 100%;
  }
}

.shops-ad, .shops-my {
  width: 26px;
  @include breakpoint(max-width 992px) {
    width: 25px;
  }
}

.shops-ad-icon, .about-company, .jobs-icon_shop, .comments-icon_shop, .special-offer {
  display: inline-block;
  size: 21px 23px;
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
  top: 7px;
  margin-right: 5px;
}

.shops-ad-icon {
  background: url("../img/shops-header-icon/ad-shoops.png") center center no-repeat;
}

.special-offer {
  background: url("../img/shops-header-icon/percentage.png") center center no-repeat;
}

.about-company {
  background: url("../img/shops-header-icon/about-company.png") center center no-repeat;
}

.comments-icon_shop {
  background: url("../img/shops-header-icon/edit-doc.png") center center no-repeat;
}

.jobs-icon_shop {
  background: url("../img/shops-header-icon/head.png") center center no-repeat;
}

.shops-ad {
  background: url("../img/menu-icon/shops-ad.png") center center no-repeat;
}

.shops-favorite {
  background: url("../img/menu-icon/shops-favorite.png") center center no-repeat;
}

.shops-my {
  background: url("../img/menu-icon/shops-my.png") center center no-repeat;
}

.shops-msg {
  background: url("../img/menu-icon/shops-msg.png") center center no-repeat;
}

.shops-wallet {
  background: url("../img/menu-icon/shops-wallet.png") center center no-repeat;
}

.shops-setting {
  background: url("../img/menu-icon/shops-setting.png") center center no-repeat;
}

.header-shop-fon {
  width: 100%;
  float: left;
  position: relative;
  .header-fons-log {
    display: block;
    position: relative;
    float: left;
    width: 100%;
  }
  @include breakpoint(max-width 992px) {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .geo-pic {
    @include breakpoint(max-width 660px) {
      display: none;
    }
  }
  img {
    width: 100%;
    object-fit: cover;
  }
  .about-shop {
    width: 100%;
    float: left;
    position: relative;
    text-align: center;
    padding: 7px 0;
    box-sizing: border-box;
    @include breakpoint(max-width 992px) {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: center;
    }
    h2 {
      display: inline-block;
      margin: 0;
      padding: 0;
      font-size: 24px;
      font-family: $osb;
      float: left;
      width: 33%;
      text-align: left;
      @include breakpoint(max-width 992px) {
        width: 100%;
        text-align: center;
        margin-bottom: 10px;
      }
    }
    p {
      float: left;
      display: inline-block;
      font-size: 18px;
      font-family: $ob;
      margin: 0;
      color: #000;
      width: 33%;
      line-height: 26px;
      @include breakpoint(max-width 992px) {
        width: 50%;
      }
      @include breakpoint(max-width 660px) {
        width: 100%;
      }
      span {
        padding-left: 10px;
        box-sizing: border-box;
        font-size: 12px;
        color: #646464;
        font-family: $or;
        @include breakpoint(max-width 992px) {
          display: block;
        }
      }
      &:last-of-type {
        font-size: 14px;
        @include breakpoint(max-width 992px) {
          font-size: 12px;
          color: #334a51;
        }
      }
    }
  }
  .shop-discount {
    width: 100%;
    float: left;
    display: inline-block;
    background-color: #d4b50a;
    text-align: center;
    padding: 15px 0;
    box-sizing: border-box;
    p {
      margin: 0;
      font-size: 14px;
      font-family: $or;
      color: #000;
    }
  }
}

.header-shop_bottom {
  width: 100%;
  float: left;
  position: relative;
  .search-map {
    background-image: url("../img/search-map-long.png");

  }

  .nachalo {
    background-color: #fff;
  }
}

.shop-content {
  width: 100%;
  float: left;
  position: relative;
  @include breakpoint(max-width 992px) {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  &__left {
    width: 65%;
    float: left;
    position: relative;
    @include breakpoint(max-width 992px) {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      order: 2;
    }
    &_item {
      width: 100%;
      float: left;
      position: relative;
      border-bottom: 1px solid #dedede;
      padding: 10px 0;
      box-sizing: border-box;
      &:last-of-type {
        border-bottom: none;
      }
    }
  }
  &__right {
    width: 35%;
    float: left;
    position: relative;
    padding-left: 100px;
    box-sizing: border-box;
    text-align: center;
    @include breakpoint(max-width 992px) {
      width: 100%;
      order: 1;
      padding-left: 0;
    }
    p {
      text-align: left;
      width: 100%;
      font-family: $or;
      font-size: 12px;
      color: $green;
      background-color: white;
      margin-bottom: 6px;
      display: block;
      position: relative;
      float: left;
      span {
        font-family: $ob;
        color: $green;
      }
    }
    h2 {
      width: 100%;
      display: block;
      position: relative;
      float: left;
      margin: 0;
      font-size: 14px;
      font-family: $ob;
      color: $green;
      padding: 10px 0 10px 15px;
      box-sizing: border-box;
      background-color: $bezh;
      text-align: left;
    }
    .mini-social {
      margin: 15px 0 18px;
    }
    .header__top_user {
      width: 100%;
      margin: 8px 0;
      float: left;
      @include breakpoint(max-width 992px) {
        width: 38%;
      }
    }
    .user-pic {
      float: left;
      width: 31px;
      height: 31px;
      border-radius: 50%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .user-name {
      float: left;
      text-align: left;
    }

    .banner-1 {
      float: left;
      margin-top: 0;

    }
    .favorite-shop-button, .mapsearch-shop-button, .complain-shop-button {
      display: inline-block;
      width: 100%;
      text-decoration: none;
      border: 1px solid #b5b5b5;
      border-right: none;
      border-top-left-radius: 14px;
      border-bottom-left-radius: 14px;
      box-sizing: border-box;
      font-family: $ob;
      font-size: 12px;
      color: $green;
      font-weight: 600;
      padding: 7px 0 7px 15px;
      line-height: 19px;
      margin: 3px 0;
      text-align: left;
      @include breakpoint(max-width 992px) {
        width: 30%;
        margin: 0;
      }
      @include breakpoint(max-width 660px) {
        width: 100%;
      }
      .average-ad-star {
        float: left;
        top: 2px;
      }
    }
    .favorite-shop-icon, .geo-shop-icon, .complain-icon {
      display: inline-block;
      margin-right: 8px;
      background-image: url("../img/star-button.png");
      size: 16px;
      top: 3px;
      position: relative;
      background-repeat: no-repeat;
      background-size: contain;
    }
    .geo-shop-icon {
      background-image: url("../img/big-geo.png");
    }
    .complain-icon {
      background-image: url("../img/alert-icon.png");
    }
    .write-author {
      // width: 64%;
      width: 100%;
      height: 35px;
      line-height: 27px;
      text-align: left;
      margin: 6px auto 15px;
      display: inline-block;
      float: none;
      @include breakpoint(max-width 992px) {
        margin: 15px 0;
      }
      &:hover {
        color: #fff;
        text-decoration: none;
      }
      .open-mail {
        margin-right: 7px;
        top: 3px;
        position: relative;
      }
    }
  }
}

.logo-organization-wrap {
  height: 100%;
  width: 15%;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  overflow: hidden;
  img {
    size: 100%;
    object-fit: cover;
  }
  @include breakpoint(min-width 1900px) {
    left: 0
  }
  @include breakpoint(max-width 992px) {
    display: none;
  }
}
